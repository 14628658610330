import React from "react";
import {Accordion, Card, Col, Row, Table} from "react-bootstrap";
import GetHeatScores from "./training-get-heat-scores";
import moment from "moment";
import {ModalAlert} from "../Modal/modal-alert";
import {ImBin} from "react-icons/im";
import {DELETE_HEAT} from "../../constants/modal";

const PastHeats = (props) => {
    const {training, heats, permissions, handleSeeCompetitor, handleDeleteHeat} = props;
    return (
        <Card className="pt-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                Past heats
            </Card.Header>
            <Card.Body>
                <Accordion>
                    {heats.length !== 0 && heats.map((heat, key) =>
                        <Card key={heat.key}>
                            <Accordion.Item eventKey={heat.key}>
                                <Accordion.Header>Heat {heats.length - key}</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <GetHeatScores training={training} heat={heat}
                                                           currentHeat={false}
                                                           handleSeeCompetitor={handleSeeCompetitor}/>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Table responsive>
                                                <tbody>
                                                <tr>
                                                    <th scope="row">Started</th>
                                                    <td>{heat.heat.startTime && moment(heat.heat.startTime).format(`LLL`)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Finished</th>
                                                    <td>{heat.heat.endTime && moment(heat.heat.endTime).format(`LLL`)}</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Card.Footer>
                                {permissions &&
                                    <ModalAlert className="float-right"
                                                name={<ImBin/>}
                                                body={DELETE_HEAT}
                                                handleAction={() => handleDeleteHeat(heat.key)}/>
                                }
                                <small className="text-muted">
                                    Created: {heat.heat.created && moment(heat.heat.created).fromNow()}
                                </small>
                            </Card.Footer>
                        </Card>
                    )}
                </Accordion>
            </Card.Body>
        </Card>
    );
}

export default PastHeats;