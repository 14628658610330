import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import GetSurfer from '../Surfer/get-surfer';
import {Link} from "react-router-dom";
import {ModalAlert} from "../Modal/modal-alert";
import {DELETE_TRAINING} from "../../constants/modal";
import {ImBin} from "react-icons/im/index";
import {ButtonGroup, ButtonToolbar, Pagination} from "react-bootstrap";
import {BsFolderSymlinkFill} from "react-icons/bs";
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import {withFirebase} from "../Firebase";
import {MdQueryStats} from "react-icons/md";

const TrainingListFormPagination = (props) => {
    const {trainings, loading, handleDeleteTraining} = props;
    const rows = trainings.length;
    const rowsPerPage = 5;
    const lastPage = Math.ceil(rows / rowsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setData(trainings.slice(0, rowsPerPage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainings]);

    useEffect(() => {
        displayPaginationTrainings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleClick = (page) => {
        setCurrentPage(page);
        const pageIndex = page - 1;
        const firstIndex = pageIndex * rowsPerPage;
        const lastIndex = pageIndex * rowsPerPage + rowsPerPage;
        setData(trainings.slice(firstIndex, lastIndex));
    };

    const displayPaginationTrainings = () => {
        let links = [];
        for (let number = 1; number <= lastPage; number++) {
            links.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handleClick(number)}>
                    {number}
                </Pagination.Item>,
            );

        }
        setItems(links);
    }

    return (
        <>
            <h1>Dashboard | Trainings</h1>
            {loading && <div>Loading...</div>}
            {data &&
                <Table striped hover size="sm" responsive>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Time</th>
                        <th>Created by</th>
                        <th>Judged by</th>
                        <th>Surfers</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(training => (
                        <tr key={training.key + '-training'}>
                            <td>{training.title}</td>
                            <td>{training.trainingTime} minutes</td>
                            <td>
                                <GetSurfer uid={training.author}/>
                            </td>
                            <td>{training.judges && Object.keys(training.judges).map((uid) => (
                                <li key={uid + '-judge'} className="no-style">
                                    <GetSurfer uid={uid}/>
                                </li>))}
                            </td>
                            <td>{training.surfers && Object.keys(training.surfers).map((uid) => (
                                <li key={uid + '-surfer'} className="no-style">
                                    <GetSurfer uid={uid}/>
                                </li>))}
                            </td>
                            <td>
                                <ButtonToolbar aria-label="actions">
                                    <ButtonGroup className="me-2" aria-label="accept group">
                                        <Link to={'/admin/training/' + training.key}
                                              className="btn btn-primary">
                                            <BsFolderSymlinkFill/>
                                        </Link>
                                    </ButtonGroup>
                                    <ButtonGroup className="me-2" aria-label="accept group">
                                        <Link to={'/admin/training/' + training.key + '/statistics'}
                                              className="btn btn-primary">
                                            <MdQueryStats/>
                                        </Link>
                                    </ButtonGroup>
                                    <ButtonGroup className="me-2" aria-label="Second group">
                                        <ModalAlert className="float-end"
                                                    body={DELETE_TRAINING}
                                                    name={<ImBin/>}
                                                    handleAction={(event) => handleDeleteTraining(event, training.key)}/>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }
            {items.length > 1 &&
                <Pagination>
                    {items}
                </Pagination>
            }
        </>
    );

}

const condition = authUser =>
    authUser && authUser.role === ROLES.ADMIN

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(TrainingListFormPagination);


