import {Row, Col, Card} from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import SignInForm from './sign-in-form';
import {FiLogIn} from "react-icons/fi";
import GoogleSignInForm from "./google-sign-in-form";

const SignIn = () => {
    return (
        <Container className="content pt-5">
            <Row>
                <Col md={{span: 3, offset: 4}}>
                    <Card className="pt-3">
                        <Card.Header><FiLogIn/> Sign In</Card.Header>
                        <Card.Body>
                            <div className="manual-section">
                                <SignInForm/>
                            </div>
                            <div className="divider-wrapper">
                                <span className="divider">Or</span>
                            </div>
                            <div className="social-section">
                                <GoogleSignInForm/>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default SignIn;
