import React, {useEffect, useState} from 'react';
import {withAuthorization, withEmailVerification} from '../Session';
import {Container, Row, Col, Card} from "react-bootstrap";
import DashboardCreateTrainingForm from "./dashboard-create-training";
import DashboardTrainings from "./dashboard-trainings";
import {compose} from "recompose";
import {getAnalytics, logEvent} from "firebase/analytics";
import {onValue} from "firebase/database";

const Dashboard = (props) => {
    const [loading, setLoading] = useState(true);
    const [trainingTime, setTrainingTime] = useState(30);
    const [trainings, setTrainings] = useState([]);
    const [form, setForm] = useState({title: '', surfers: [], judges: []});
    const [validateSurfers, setValidateSurfers] = useState(false);
    const [validateJudges, setValidateJudges] = useState(false);
    const [isNewTrainingLoading, setIsNewTrainingLoading] = useState(false);
    const [currentSurfer] = useState(props.firebase.currentSurferData());
    const authorEmail = props.firebase.currentUser().email;
    const authorUid = props.firebase.currentUser().uid;
    const authorSubscription = props.firebase.currentSurferData().subscription;
    const admin = props.firebase.currentSurferData().role === 'Administrator';
    const firebaseRef = props.firebase.trainings();

    useEffect(() => {
        onValue(firebaseRef, onFirebaseValueChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorUid, authorEmail]);

    const getTrainings = () => {
        if (authorEmail && authorUid) {
            const getTrainings = props.firebase.cloud('getTrainings');
            getTrainings().then(result => {
                setTrainings(result.data);
                setLoading(false);
                setIsNewTrainingLoading(false);
            });
        }
    }

    const onFirebaseValueChanged = snapshot => {
        getTrainings();
    }

    const handleCreateTraining = (event) => {
        setIsNewTrainingLoading(true);
        if (authorUid) {
            const newTrainingKey = props.firebase.createTraining();
            let training = {
                author: authorUid,
                trainingId: newTrainingKey,
                trainingTime: trainingTime,
                title: form.title,
                surfers: form.surfers,
                judges: form.judges,
            };
            const createTraining = props.firebase.cloud('createTraining');
            createTraining(training).then(result => {
                if (!result.data.validateJudges) {
                    setValidateJudges(result.data.message);
                    setIsNewTrainingLoading(false);
                    return;
                }
                if (!result.data.validateSurfers) {
                    setValidateSurfers(result.data.message);
                    setIsNewTrainingLoading(false);
                    return;
                }
                if (result.data.validateSurfers && result.data.validateJudges) {
                    training.surfers.forEach(surfer => {
                        // if the surfer.id is different than authorUid, send notification
                        if (surfer.id !== authorUid) {
                            sendNotification(training.trainingId, surfer.id, 'surfer');
                        }
                    });
                    training.judges.forEach(judge => {
                        if (judge.id !== authorUid) {
                            sendNotification(training.trainingId, judge.id, 'judge');
                        }
                    });
                }
                const analytics = getAnalytics();
                logEvent(analytics, 'create_training');
                setTrainingTime(30);
                setForm({title: '', surfers: [], judges: []});
            });
        }

        event.preventDefault();
    }

    const sendNotification = (trainingId, receiverUid, type) => {
        let senderFullName = currentSurfer.name + ' ' + currentSurfer.surname;
        onValue(props.firebase.surferEmail(receiverUid), snapshot => {
            const receiverEmail = snapshot.val();
            if (!receiverEmail) {
                return;
            }
            const addEmail = props.firebase.cloud('addEmail');
            addEmail({
                trainingId: trainingId,
                senderFullName: senderFullName,
                uid: receiverUid,
                email: receiverEmail,
                type: type
            });
        });
    }

    const handleDeleteTraining = (event, trainingId) => {
        if (trainingId) {
            const deleteTraining = props.firebase.cloud('deleteTraining');
            deleteTraining({trainingId: trainingId});
        }
    }

    const handleTrainingTime = (value) => {
        setTrainingTime(value);
    }

    const handleSurfers = (value) => {
        setValidateSurfers(false);
        setForm({...form, surfers: value});
    }

    const handleJudges = (value) => {
        setValidateJudges(false);
        setForm({...form, judges: value});
    }

    const handleChange = (event) => {
        setForm({...form, [event.target.id]: event.target.value});
    }

    return (
        <Container className="content p-2 h-100">
            <Row>
                {(authorSubscription || admin) &&
                    <Col xs={12} md={3}>
                        <Card className="pt-3">
                            <Card.Header>Create training</Card.Header>
                            <Card.Body>
                                <DashboardCreateTrainingForm
                                    authorSubscription={authorSubscription} admin={admin}
                                    validateJudges={validateJudges} validateSurfers={validateSurfers}
                                    form={form} trainingTime={trainingTime} isNewTrainingLoading={isNewTrainingLoading}
                                    handleTrainingTime={handleTrainingTime}
                                    handleSurfers={handleSurfers} handleJudges={handleJudges}
                                    onSubmit={handleCreateTraining} handleChange={handleChange}/>
                            </Card.Body>
                        </Card>
                    </Col>
                }
                <Col>
                    <Card className="pt-3">
                        <Card.Header>Trainings</Card.Header>
                        <Card.Body>
                            <DashboardTrainings loading={loading} trainings={trainings} authorUid={authorUid}
                                                handleDeleteTraining={handleDeleteTraining}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(Dashboard);