import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import moment from "moment";
import {Link} from "react-router-dom";
import AdminSidebar from "./admin-sidebar";
import {ButtonGroup, ButtonToolbar, Col, Container} from "react-bootstrap";
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import {withFirebase} from "../Firebase";
import {BsFolderSymlinkFill} from "react-icons/bs";
import {onValue} from "firebase/database";


const BotsList = (props) => {
    return (
        <Container className="content min-vh-100">
            <div className="row pt-3">
                <Col sm={12} md={2}>
                    <AdminSidebar/>
                </Col>
                <Col sm={12} md={10}>
                    <BotsListForm {...props}/>
                </Col>
            </div>
        </Container>
    )
}

const BotsListForm = (props) => {
    const [bots, setBots] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onListenForBots();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onListenForBots = () => {
        setLoading(true);
        onValue(props.firebase.bots(), snapshot => {
            const botsObject = snapshot.val();
            if (botsObject) {
                const botsList = Object.keys(botsObject).map(key => ({
                    ...botsObject[key],
                    uid: key,
                }));
                setBots(botsList);
            }
            setLoading(false);
        });
    };

    return (
        <>
            <h1>Dashboard | Bots</h1>
            {loading && <div>Loading ...</div>}
            <Table striped hover size="sm" responsive>
                <thead>
                <tr>
                    <th>Full name</th>
                    <th>Country</th>
                    <th>Age</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {bots.map(bot => (
                    <tr key={bot.uid}>
                        <td>{bot.name} {bot.middleName} {bot.surname}</td>
                        <td>{bot.country}</td>
                        <td>{bot.dob ? moment().diff(bot.dob, 'years') : ''}</td>
                        <td>
                            <ButtonToolbar aria-label="actions">
                                <ButtonGroup className="me-2" aria-label="accept group">
                                    <Link to={'/admin/bot/' + bot.uid}
                                          className="btn btn-primary">
                                        <BsFolderSymlinkFill/>
                                    </Link>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
}

const condition = authUser =>
    authUser && authUser.role === ROLES.ADMIN

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(BotsList);