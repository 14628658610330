import React, {useEffect, useState} from 'react';
import {withFirebase} from "../Firebase";
import 'react-input-range/lib/css/index.css';
import moment from 'moment';
import GetSurfer from "../Surfer/get-surfer";
import {Table, ButtonGroup, ButtonToolbar, Pagination} from "react-bootstrap";
import {ModalAlert} from "../Modal/modal-alert";
import {ImBin} from "react-icons/im";
import {BsFolderSymlinkFill} from "react-icons/bs";
import {MdQueryStats} from "react-icons/md";
import {Link} from "react-router-dom";
import {DELETE_TRAINING} from "../../constants/modal";
import Loading from "../Views/loading";

const DashboardTrainings = (props) => {
    const {loading, trainings, handleDeleteTraining, authorUid} = props;
    const rows = trainings.length;
    const rowsPerPage = 5;
    const lastPage = Math.ceil(rows / rowsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setData(trainings.slice(0, rowsPerPage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainings]);

    useEffect(() => {
        displayPaginationTrainings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleClick = (page) => {
        setCurrentPage(page);
        const pageIndex = page - 1;
        const firstIndex = pageIndex * rowsPerPage;
        const lastIndex = pageIndex * rowsPerPage + rowsPerPage;
        setData(trainings.slice(firstIndex, lastIndex));
    };

    const displayPaginationTrainings = () => {
        let links = [];
        for (let number = 1; number <= lastPage; number++) {
            links.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handleClick(number)}>
                    {number}
                </Pagination.Item>,
            );

        }
        setItems(links);
    }

    return (
        <div>
            {loading && <Loading size="xsm"/>}
            {data.length > 0 &&
                <Table striped hover size="sm" responsive>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Created</th>
                        <th>Time</th>
                        <th>Created by</th>
                        <th>Judged by</th>
                        <th>Competitors</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(training => (
                        <tr key={training.key}>
                            <td>{training.training.title}</td>
                            <td>{moment(training.training.created).format('LLL')}</td>
                            <td>{training.training.trainingTime} minutes</td>
                            <td>
                                <GetSurfer uid={training.training.author}/>
                            </td>
                            <td>
                                {training.training.judges && Object.keys(training.training.judges).map((uid, i) => (
                                    <li key={uid} className="no-style">
                                        <GetSurfer uid={uid}/>
                                    </li>
                                ))}
                            </td>
                            <td>
                                {training.training.surfers && Object.keys(training.training.surfers).map((uid, i) => (
                                    <li key={uid} className="no-style">
                                        <GetSurfer uid={uid}/>
                                    </li>
                                ))}
                            </td>
                            <td>
                                <ButtonToolbar aria-label="actions">
                                    <ButtonGroup className="me-2" aria-label="accept group">
                                        <Link to={'/dashboard/training/' + training.key}
                                              className="btn btn-primary">
                                            <BsFolderSymlinkFill/>
                                        </Link>
                                    </ButtonGroup>
                                    <ButtonGroup className="me-2" aria-label="accept group">
                                        <Link to={'/dashboard/training/' + training.key + '/statistics'}
                                              className="btn btn-primary">
                                            <MdQueryStats/>
                                        </Link>
                                    </ButtonGroup>
                                    {training.training.author === authorUid &&
                                        <ButtonGroup className="me-2" aria-label="Second group">
                                            <ModalAlert className="float-end"
                                                        body={DELETE_TRAINING}
                                                        name={<ImBin/>}
                                                        handleAction={(event) => handleDeleteTraining(event, training.key)}/>
                                        </ButtonGroup>
                                    }
                                </ButtonToolbar>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }
            {!loading && data.length === 0 &&
                <div>No training</div>
            }
            {items.length > 1 &&
                <Pagination>
                    {items}
                </Pagination>
            }
        </div>
    );
}

export default withFirebase(DashboardTrainings);