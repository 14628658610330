import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

const ProductHero = () => {
    return (
        <div className="container-full-bg">
            <Container>
                <Row>
                    <Col xs={12} md={8} className="pt-5 jumbotron-left">
                        <div className="jumbotron-download">
                            <h1 className="jumbotron-title brand-font">Be the best.</h1>
                            <h2 className="jumbotron-paragraph">
                                For coaches and professional surfers.
                            </h2>
                        </div>
                        <div className="pt-4 product-text">
                            <p>
                                <b>PROSURFINGTOOLS</b> is a web application that <b>replicates the experience of
                                real-life surfing competitions</b>. With the ability to assemble your own team, the
                                platform
                                enables you to design various training exercises.
                            </p>
                            <p>You have control over the selection of judges and
                                surfers, allowing for different scenarios. <b>Compete against virtual
                                    opponents or train with other surfers directly</b>. The judges initiate the heats,
                                and actively score the waves while also managing other factors such
                                as priority.
                            </p>
                            <p>By utilizing this platform, you can effectively simulate and <b>prepare for the different
                                stressful situations</b> you are likely to encounter in
                                real competitions.</p>
                        </div>
                        <div className="pt-2">
                            <a href="/signup" className="btn btn-primary btn-lg">REGISTER NOW!</a>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="jumbotron-right"></Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductHero;
