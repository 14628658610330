import React from 'react';
import ProductHero from "./product-hero";
import ProductDescription from "./product-description";
import {ProductNewsletter} from "./product-newsletter";
import News from "./news";
import ProductHow from "./product-how";

const LandingPage = () => (
    <div>
        <div className="bg-homepage">
            <ProductHero/>
            {/*<ProductNewsletter/>*/}
            <ProductDescription/>
        </div>
        <ProductHow/>
        <News/>

    </div>
)

export default LandingPage;