export const DELETE_USER = 'This action cannot be undone. This will permanently remove your user account, and you will not be able to login again. ' +
    'However, we will only anonymise your profile details, so those trainings you have created, judged or participated will ' +
    'be still available for the rest of your team.';

export const DELETE_TRAINING = 'This action cannot be undone. This will permanently remove the training, plus all the heats and scores associated to this training.';

export const ADD_TEAM_MEMBER = 'This will add this user to your team. You will be able to add it as ' +
    ' a judge, or as a surfer in your training.';

export const DELETE_TEAM_MEMBER = 'This will remove this user from your team. You will not be able to add it as ' +
    'a judge, or as a surfer in your training.';

export const DELETE_HEAT = 'This action cannot be undone. This will permanently remove this heat, and all the scores associated.';

export const FINISH_HEAT = 'This action cannot be undone. This finish this heat prematurely, and select a winner.';


