import React, {useEffect, useState} from 'react';
import {withFirebase} from "../Firebase";
import {Image} from "react-bootstrap";
import {onValue} from "firebase/database";

export const CurrentUserImage = (props) => {
    const {surfer} = props;
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState('/default-profile.png');

    useEffect(() => {
            setImage(surfer.picture ? surfer.picture : image);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    return (
        <>
            {loading && <div>Loading ...</div>}
            <Image src={image} height="20" roundedCircle className="profile-img-nav"/>
        </>

    );
}

const CurrentUser = (props) => {
    const [state, setState] = useState({
        surfer: null,
        loading: false
    });

    const getSurferData = () => {
        setState({...state, loading: true});
        onValue(props.firebase.surfer(props.firebase.currentUser().uid), snapshot => {
            const surfer = snapshot.val();
            setState({
                surfer: surfer,
                loading: false
            });
        });
    }

    const {surfer, loading} = state;

    useEffect(() => {
            getSurferData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    return (
        <div className="dropdown-element">
            {loading && <div>Loading ...</div>}
            {surfer && (
                <div>
                    <CurrentUserImage surfer={surfer}/>
                    <span className='nav-text p-2'>{surfer.name} {surfer.surname}</span>
                </div>
            )}
        </div>
    );
}

export default withFirebase(CurrentUser);