import React from 'react';
import AuthUserContext from "./context";
import {withFirebase} from "../Firebase";
import {Button, Card, Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

const withEmailVerification = Component => {
    class WithEmailVerification extends React.Component {
        constructor() {
            super();

            this.state = {
                isSent: false
            };
        }

        onSendEmailVerification = () => {
            if (!this.state.isSent) {
                this.props.firebase
                    .doSendEmailVerification()
                    .then(() => this.setState({isSent: true}));
            }
        }

        render() {
            return (
                <>
                    <AuthUserContext.Consumer>
                        {authUser =>
                            needsEmailVerification(authUser) ? (
                                <Container className="p-5">
                                    <Row>
                                        <Col md={{span: 3, offset: 4}}>
                                            <Card className="pt-3">
                                                <Card.Header>Confirmation email</Card.Header>
                                                <Card.Body>
                                                    <div className="card-text">
                                                        {this.state.isSent ? (
                                                            <p>
                                                                We have sent you an email! Please verify your email with
                                                                the link we sent.
                                                                Please check your inbox (spam folder included).
                                                            </p>
                                                        ) : (
                                                            <p>We sent you an email. Please check your inbox (spam
                                                                folder included).
                                                                If you have not received it, you can try again here.</p>
                                                        )}
                                                        <Button onClick={this.onSendEmailVerification()}
                                                                variant="primary"
                                                                disabled={this.state.isSent}>Send confirmation
                                                            email</Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            ) : (
                                <Component {...this.props} />
                            )
                        }
                    </AuthUserContext.Consumer>
                </>
            )
        }
    }

    return withFirebase(WithEmailVerification);
}

export default withEmailVerification;

const needsEmailVerification = authUser =>
    authUser && !authUser.emailVerified && authUser.providerData.map(provider => provider.providerId).includes('password');