import React from 'react';
import {AuthUserContext, withAuthorization, withEmailVerification} from '../Session';
import Surfer from "../Surfer/surfer";
import {compose} from "recompose";

const AccountPage = () => (
    <AuthUserContext.Consumer>
        {authUser => (
            <span>
                <Surfer uid={authUser && authUser.uid}/>
            </span>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(AccountPage);