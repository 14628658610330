import React from 'react';
import {Accordion, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const SubscribeFaq = () => {
    return (
        <Card className="pt-3">
            <Card.Body>
                <Accordion>
                    <Accordion.Item eventKey={0}>
                        <Accordion.Header>What is the difference between free and pro account?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Our <b>free account</b> is designed for surfers with a coach, providing access to our
                                website
                                and smartwatch app. With this account, you can view your training sessions and related
                                information from both devices, including a list of your added sessions, past heat
                                details, current heat information such as priority, countdown, scores, and leading
                                surfer. Additionally, you can access and download your statistics.
                            </p>
                            <ul>
                                <li>List of trainings you have been added as a surfer</li>
                                <li>Display information about past heats</li>
                                <li>Display current heat with information about:</li>
                                <ul>
                                    <li>Priority</li>
                                    <li>Countdown</li>
                                    <li>Scores</li>
                                    <li>Who is winning</li>
                                </ul>
                                <li>Display and download statistics</li>
                            </ul>
                            <p>
                                Our <b>pro account</b> is ideal for both surfers and coaches, enabling them to create
                                and
                                customize their training sessions. With this account, you can select surfers and judges
                                from your team, set heat times, create heats, prioritize and add waves, score waves, and
                                start/finish heats.
                            </p>
                            <ul>
                                <li>Create trainings</li>
                                <ul>
                                    <li>Select surfers from the team</li>
                                    <li>Select judges from the team</li>
                                    <li>Set the time for each heat</li>
                                </ul>
                                <li>Create heats</li>
                                <ul>
                                    <li>Set priority</li>
                                    <li>Add waves</li>
                                    <li>Score waves</li>
                                </ul>
                                <li>Start and finish heats</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={1}>
                        <Accordion.Header>Why is call pro and no coach account?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                At our company, we recognize that not all surfers have a coach. Therefore, we offer the
                                unique opportunity for surfers to train on their own using a single account. With this
                                option, you can serve as both the surfer and the coach, adding yourself to the training
                                and acting as a judge. Alternatively, you can enlist the help of someone else to serve
                                as the judge while you're in the water
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2}>
                        <Accordion.Header>How can I cancel my account?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                To cancel your subscription, simply visit the 'Stripe Customer Portal' and access the
                                cancellation link provided on this page or within your <Link
                                to={ROUTES.ACCOUNT}>account</Link>.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card.Body>
        </Card>
    );
};

export default SubscribeFaq;