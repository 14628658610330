import React from 'react';
import {ButtonGroup, Card, ListGroup} from "react-bootstrap";
import {AuthUserContext} from "../Session";
import CustomerPortal from "./customer-portal";
import SubscribeLink from "./subscribe-link";

const SubscribeCard = (props) => {
    const {product, price} = props;
    return (
        <Card className="p-2">
            <Card.Img variant="top" width="50%" src={product.images[0]}/>
            <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <div className="product-body">
                    <p>{product.description}</p>
                    <span className="product-price-quantity">£{price.unit_amount / 100}</span>
                    <small className="text-muted">/month</small>
                </div>
                <ButtonGroup className="d-flex">
                    <AuthUserContext.Consumer>
                        {(authUser) =>
                            (authUser.subscription ?
                                <CustomerPortal/> :
                                <SubscribeLink/>)
                        }
                    </AuthUserContext.Consumer>
                </ButtonGroup>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroup.Item>Build a team, and recreate real competitions</ListGroup.Item>
                <ListGroup.Item>Create trainings, and heats</ListGroup.Item>
                <ListGroup.Item>Be a judge and add scores</ListGroup.Item>
                <ListGroup.Item>Live scores in all devices</ListGroup.Item>
            </ListGroup>
        </Card>
    );
};

export default SubscribeCard;