import React, {useState, useEffect} from 'react';
import {withFirebase} from "../Firebase";
import {BsPersonCheck} from "react-icons/bs";
import {ModalAlert} from "../Modal/modal-alert";
import {ADD_TEAM_MEMBER} from "../../constants/modal";
import {PENDING} from "../../constants/status";
import {onValue} from "firebase/database";

const TeamAcceptMember = (props) => {
    const {uid, currentUser, handleAccept} = props;
    const [pending, setPending] = useState(false);

    useEffect(() => {
        getMemberStatusPending();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pending]);

    const getMemberStatusPending = () => {
        onValue(props.firebase.surferTeamMember(currentUser, uid), snapshot => {
            if (snapshot.val() === PENDING) {
                setPending(true);
            } else {
                setPending(false);
            }
        });
    }

    return (
        <>
            {uid && pending && <WithoutPermissions uid={uid} handleAccept={handleAccept}/>}
        </>
    );
}

const WithoutPermissions = (props) => (
    <ModalAlert className="float-end"
                body={ADD_TEAM_MEMBER}
                name={<BsPersonCheck/>}
                handleAction={(event) => props.handleAccept(event, props.uid)}/>
);

export default withFirebase(TeamAcceptMember);