import React, {useState, useEffect} from 'react';
import {withFirebase} from "../Firebase";
import {onValue, query, orderByChild, equalTo} from "firebase/database";

const TrainingGetScoreTotal = (props) => {
    const {waveId, surferWaves} = props;
    const [loading, setLoading] = useState(true);
    const [found, setFound] = useState(false);
    const [total, setTotal] = useState(0);

    const getScoreTotal = () => {
        const scores = query(props.firebase.scores(), orderByChild("waveId"), equalTo(waveId));
        let calc = 0;
        onValue(scores, snapshot => {
            const scoreObjects = snapshot.val();
            if (scoreObjects) {
                Object.keys(scoreObjects).forEach(id => {
                    calc = scoreObjects[id].score + calc;
                });
                calc = calc / Object.keys(scoreObjects).length;
                setFound(true);
                setTotal(calc);
            }
            setLoading(false)
        });
    }

    useEffect(() => {
        getScoreTotal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surferWaves]);

    return (
        <>
            {loading && <div>Loading ...</div>}
            {!found && <div></div>}
            {found && <span>{total}</span>}
        </>
    );
}

export default withFirebase(TrainingGetScoreTotal);