import React from 'react';
import {compose} from 'recompose';
import {withFirebase} from "../Firebase";
import {AuthUserContext, withAuthorization, withEmailVerification} from '../Session';
import {Card} from "react-bootstrap";
import CustomerPortal from "../Stripe/customer-portal";

const SurferStripe = (props) => {
    return (
        <>
            <AuthUserContext.Consumer>
                {(authUser) =>
                    (authUser.subscription ?
                        <Card className="pt-3">
                            <Card.Header>Subscription</Card.Header>
                            <Card.Body>
                                <CustomerPortal/>
                            </Card.Body>
                        </Card> :
                        '')
                }
            </AuthUserContext.Consumer>
        </>
    );

}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase,
)(SurferStripe);