import React from 'react';
import {withFirebase} from "../Firebase";
import {ModalAlert} from "../Modal/modal-alert";
import {ImBin} from "react-icons/im";
import {DELETE_TEAM_MEMBER} from "../../constants/modal";

const TeamRemoveMember = (props) => {
    const {uid, handleRemove} = props;

    return (
        <>
            {uid && <WithoutPermissions uid={uid} handleRemoveMember={handleRemove}/>}
        </>
    );
}

const WithoutPermissions = (props) => (
    <ModalAlert className="float-end"
                body={DELETE_TEAM_MEMBER}
                name={<ImBin/>}
                handleAction={(event) => props.handleRemoveMember(event, props.uid)}/>
);

export default withFirebase(TeamRemoveMember);