import React, {useEffect, useState} from 'react';
import AdminSidebar from "./admin-sidebar";
import {Col, Container} from "react-bootstrap";
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import {withFirebase} from "../Firebase";
import AdminSurfersPagination from "./admin-surfers-pagination";
import {onValue} from "firebase/database";

const SurfersList = (props) => {
    return (
        <Container className="content  min-vh-100">
            <div className="row pt-3">
                <Col sm={12} md={2}>
                    <AdminSidebar/>
                </Col>
                <Col sm={12} md={10}>
                    <SurfersListForm {...props}/>
                </Col>
            </div>
        </Container>
    )
}

const SurfersListForm = (props) => {
    const [surfers, setSurfers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onListenForSurfers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTeamMembers = async (surfersList) => {
        for (const surfer of surfersList) {
            const authorTeam = props.firebase.surferTeam(surfer.uid);
            await onValue(authorTeam, snapshot => {
                const teamObject = snapshot.val();
                if (teamObject) {
                    surfer.members = Object.keys(teamObject).length;
                }
            });
        }
        return surfersList;
    }

    const onListenForSurfers = () => {
        setLoading(true);
        onValue(props.firebase.surfers(), async snapshot => {
            const surfersObject = snapshot.val();
            if (surfersObject) {
                let surfersList = Object.keys(surfersObject).map(key => ({
                    ...surfersObject[key],
                    uid: key,
                }));
                surfersList = await getTeamMembers(surfersList).then(() => {
                    // Sort the surfersList by created time
                    surfersList.sort((a, b) => b.created - a.created);
                    setSurfers(surfersList)
                });
            }
            setLoading(false);
        });
    };

    return (
        <AdminSurfersPagination surfers={surfers} loading={loading}/>
    );
}

const condition = authUser =>
    authUser && authUser.role === ROLES.ADMIN

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(SurfersList);