import React from 'react';
import {withFirebase} from "../Firebase";
import Page from "./page";

/**
 * Source; https://reactrocket.com/post/draft-js-persisting-content/
 */
const Terms = () => (
    <Page pageName="terms-and-conditions"/>
);

export default withFirebase(Terms);