import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {GrStripe} from "react-icons/gr";
import {withFirebase} from "../Firebase";
import {createCheckoutSession} from "@stripe/firestore-stripe-payments";

const SubscribeLink = (props) => {
    const [loading, setLoading] = useState(false);

    const createStripeSession = async () => {
        setLoading(true);
        const session = await createCheckoutSession(props.firebase.payments, {
            price: props.firebase.price,
        });
        window.location.assign(session.url);
        setLoading(false);
    }

    return (
        <Button onClick={createStripeSession} variant="primary" disabled={loading}><GrStripe/> Start free trial</Button>

    );
};

export default withFirebase(SubscribeLink);