import React, {useState, useEffect} from 'react';
import {withFirebase} from "../Firebase";
import {ModalInput} from "../Modal/modal-input";
import {onValue, query, orderByChild, equalTo} from "firebase/database";

const TrainingAddScore = (props) => {
    const {judge, waveId, name, score, active, changeScore, saveScore, isNewScoreLoading} = props;
    const [loading, setLoading] = useState(false);
    const [found, setFound] = useState(false);

    useEffect(() => {
        onListenForSurfers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onListenForSurfers = () => {
        setLoading(true);
        const scores = query(props.firebase.scores(), orderByChild('waveId'), equalTo(waveId));
        onValue(scores, snapshot => {
            const scoresObject = snapshot.val();
            if (scoresObject) {
                Object.keys(scoresObject).forEach(key => {
                    if (scoresObject[key].judge === judge) {
                        setFound(true);
                    }
                });
            }
            setLoading(false);
        });
    };

    return (
        <>
            {loading && <div>Loading...</div>}
            {!found && active &&
                <ModalInput data={waveId} name={name}
                            handleAction={changeScore}
                            score={score}
                            isNewScoreLoading={isNewScoreLoading}
                            submit={saveScore}/>
            }
        </>
    );

}

export default withFirebase(TrainingAddScore);