import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {withRouter} from "../../Functions/router";
import {compose} from 'recompose';
import {withFirebase} from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import {Form, Button, Alert} from 'react-bootstrap';
import {TERMS} from "../../../constants/routes";
import {getAnalytics, logEvent} from "firebase/analytics";

const SignUpFormBase = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        name: '',
        surname: '',
        email: '',
        passwordOne: '',
        passwordTwo: '',
        error: null,
        checked: false,
        newsletter: false,
    });
    const [notification] = useState({
        request: true,
        accept: true,
        add: true,
    });

    useEffect(() => {
        props.firebase.onAuthUserListener(() => {
            navigate(ROUTES.DASHBOARD)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = event => {
        const {email, name, surname, passwordOne} = state;

        props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                props.firebase.cloud('saveNotification')({notification: notification});
                return props.firebase.cloud('createSurfer')({
                    email: email,
                    name: name,
                    surname: surname,
                    uid: authUser.user.uid
                })
            })
            .then(() => {
                const newUserEmail = props.firebase.cloud('newUserEmail');
                newUserEmail({fullName: name + ' ' + surname});
                if (state.newsletter) {
                    addUserNewsletter(email).then(r => console.log(r));
                }
                const analytics = getAnalytics();
                logEvent(analytics, 'sign_up', {
                    method: 'email'
                });
                setState({...state});
                navigate(ROUTES.DASHBOARD)
            })
            .catch(error => {
                setState({...state, error});
            });

        event.preventDefault();
    };

    const addUserNewsletter = async (email) => {
        const list_id = process.env.REACT_APP_MAILJET_NEWSLETTER_LIST_ID;
        const api_key = process.env.REACT_APP_MAILJET_API_KEY;
        const api_secret = process.env.REACT_APP_MAILJET_API_SECRET;
        const addNewsletter = await props.firebase.cloud('addNewsletter');
        addNewsletter({
            email: email,
            list_id: list_id,
            api_key: api_key,
            api_secret: api_secret
        }).catch((error) => {
            console.log(error);
        });
    };

    const Terms = () => (
        <div>
            Confirm you have read and accepted our <Link className="p-0" target="_blank" to={TERMS}>terms of use</Link>.
        </div>
    );

    const Newsletter = () => (
        <div>
            Subscribe to our newsletter.
        </div>
    );

    const onChange = event => {
        setState({...state, [event.target.name]: event.target.value});
    };

    const onChangeCheckboxTerms = event => {
        setState({...state, checked: event.target.checked});
    };

    const onChangeCheckboxNewsletter = event => {
        setState({...state, newsletter: event.target.checked});
    };

    const {email, passwordOne, passwordTwo, error, name, surname, checked, newsletter} = state;

    const isInvalid =
        passwordOne !== passwordTwo ||
        passwordOne === '' ||
        email === '' ||
        name === '' ||
        !checked;

    return (
        <>
            <Form onSubmit={onSubmit}>
                <Form.Group className="form-element" controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        name="name"
                        suggested="name"
                        value={name}
                        onChange={onChange}
                        type="text"
                        placeholder="Name"
                        autoComplete="on"
                    />
                </Form.Group>

                <Form.Group className="form-element" controlId="formBasicSurname">
                    <Form.Label>Surname</Form.Label>
                    <Form.Control
                        name="surname"
                        suggested="surname"
                        value={surname}
                        onChange={onChange}
                        type="text"
                        placeholder="Surname"
                        autoComplete="on"
                    />
                </Form.Group>

                <Form.Group className="form-element" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        name="email"
                        suggested="email"
                        value={email}
                        onChange={onChange}
                        type="text"
                        placeholder="Email Address"
                        autoComplete="on"
                    />
                </Form.Group>

                <Form.Group className="form-element" controlId="formBasicPasswordOne">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        name="passwordOne"
                        suggested="new-password"
                        value={passwordOne}
                        onChange={onChange}
                        type="password"
                        placeholder="Password"
                        autoComplete="on"
                    />
                </Form.Group>
                <Form.Group className="form-element" controlId="formBasicPasswordTwo">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        name="passwordTwo"
                        suggested="new-password"
                        value={passwordTwo}
                        onChange={onChange}
                        type="password"
                        placeholder="Confirm Password"
                        autoComplete="on"
                    />
                </Form.Group>
                <Form.Group className="form-element" controlId="formBasicCheckboxTerms">
                    <Form.Check
                        type="checkbox"
                        label={<Terms/>}
                        checked={checked}
                        onChange={onChangeCheckboxTerms}
                    />
                </Form.Group>
                <Form.Group className="form-element" controlId="formBasicCheckboxNewsletter">
                    <Form.Check
                        type="checkbox"
                        label={<Newsletter/>}
                        checked={newsletter}
                        onChange={onChangeCheckboxNewsletter}
                    />
                </Form.Group>
                <div className="d-grid gap-2 pb-3">
                    <Button variant="primary" type="submit" disabled={isInvalid}>
                        Sign Up
                    </Button>
                </div>
                <p>Already have an account? <Link to={ROUTES.SIGN_IN}>Sign In here</Link>.</p>
                {error && <Alert variant="danger">{error.message}</Alert>}
            </Form>
        </>
    );
};

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export {SignUpForm};
