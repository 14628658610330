import React, {useEffect, useState, useMemo} from 'react';
import {withFirebase} from "../Firebase";
import {Alert, Button, Form, ListGroup, ButtonGroup, Spinner} from "react-bootstrap";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {Typeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {ACCEPTED} from "../../constants/status";
import {GrTableAdd} from "react-icons/gr";
import {onValue, query, orderByChild} from "firebase/database";


const CreateTrainingButton = () => {
    return (
        <>
            <GrTableAdd/> New training
        </>
    )
}

const DashboardCreateTrainingForm = (props) => {
    const {
        onSubmit, handleChange, handleTrainingTime, admin, form, trainingTime, handleSurfers, handleJudges,
        authorSubscription, validateJudges, validateSurfers, isNewTrainingLoading, firebase
    } = props;

    const authorEmail = firebase.currentUser().email;
    const authorUid = firebase.currentUser().uid;
    const [surfersList, setSurfersList] = useState([]);
    const [judgesList, setJudgesList] = useState([]);

    const getAuthor = useMemo(() => {
        return () => onValue(props.firebase.surfer(authorUid), snapshot => {
            const authorObject = snapshot.val();
            if (authorObject) {
                const author = [{label: authorObject.name + ' ' + authorObject.surname, id: authorUid}];
                setSurfersList(current => [...current, ...author]);
                setJudgesList(current => [...current, ...author]);
            }
        });
    }, [authorUid, props.firebase]);

    const getBots = useMemo(() => {
        const q = query(props.firebase.bots(), orderByChild("bot"));
        return () => onValue(q, snapshot => {
            const botsObject = snapshot.val();
            if (botsObject) {
                let bots = Object.keys(botsObject).map(key => ({
                    label: botsObject[key].name + ' ' + botsObject[key].surname + ' (bot)',
                    id: key,
                }));
                setSurfersList(current => [...current, ...bots]);
            }
        });
    }, [props.firebase]);

    const getTeam = useMemo(() => {
        const authorTeam = props.firebase.surferTeam(authorUid);
        return () => {
            onValue(authorTeam, async snapshot => {
                const teamObject = snapshot.val();
                if (teamObject) {
                    const acceptedUids = Object.keys(teamObject).filter(uid => teamObject[uid] === ACCEPTED);
                    const apiRequests = acceptedUids.map(uid => {
                        const getSubscription = props.firebase.cloud('getSubscription');
                        const getMember = props.firebase.cloud('getMember');
                        return Promise.all([getSubscription({uid}), getMember({uid})]);
                    });
                    const responses = await Promise.all(apiRequests);
                    const memberJudgesList = [];
                    const memberSurfersList = [];
                    responses.forEach(([subscriptions, members]) => {
                        const subscription = subscriptions.data[0];
                        const member = members.data[0];
                        if (member && subscription) {
                            memberJudgesList.push(member);
                            memberSurfersList.push(member);
                        } else if (member) {
                            memberSurfersList.push(member);
                        }
                    });
                    setJudgesList(current => [...current, ...memberJudgesList]);
                    setSurfersList(current => [...current, ...memberSurfersList]);
                }
            });
        };
    }, [authorUid, props.firebase]);

    useEffect(() => {
        getAuthor();
        getBots();
        getTeam();
    }, [getAuthor, getBots, getTeam]);

    return (
        <ListGroup variant="flush">
            {judgesList && surfersList &&
                <Form onSubmit={onSubmit}>
                    <Form.Group className="form-element" controlId="title">
                        <Form.Label>Training title</Form.Label>
                        <Form.Control
                            value={form.title}
                            onChange={handleChange}
                            placeholder="What is this training focus on?"/>
                    </Form.Group>
                    <Form.Group className="form-element" controlId="author">
                        <Form.Label>Author</Form.Label>
                        <Form.Control value={authorEmail} disabled/>
                    </Form.Group>
                    <Form.Group className="form-element" controlId="trainingTime">
                        <Form.Label>Training time</Form.Label>
                        <InputRange
                            formatLabel={value => `${value} min.`}
                            step={5}
                            maxValue={60}
                            minValue={5}
                            value={trainingTime}
                            onChange={value => handleTrainingTime(value)}/>
                    </Form.Group>
                    <Form.Group className="form-element" controlId="judges">
                        <Form.Label>Judges</Form.Label>
                        <Typeahead
                            id="basic-typeahead-multiple"
                            multiple
                            onChange={(selected) => {
                                handleJudges(selected)
                            }}
                            labelKey="label"
                            options={judgesList}
                            placeholder="Choose judges..."
                            selected={form.judges}
                        />
                        {validateJudges &&
                            <Alert key="validate-surfers" variant="danger">
                                {validateJudges}
                            </Alert>
                        }
                    </Form.Group>
                    <Form.Group className="form-element" controlId="surfers">
                        <Form.Label>Surfers</Form.Label>
                        <Typeahead
                            id="basic-typeahead-multiple"
                            multiple
                            onChange={(selected) => {
                                handleSurfers(selected)
                            }}
                            labelKey="label"
                            options={surfersList}
                            placeholder="Choose surfers to compete..."
                            selected={form.surfers}
                        />
                        {validateSurfers &&
                            <Alert key="validate-surfers" variant="danger">
                                {validateSurfers}
                            </Alert>
                        }
                    </Form.Group>
                    <ButtonGroup className="d-flex">
                        {(authorSubscription || admin) &&
                            <Button variant="primary" type="submit" value="Submit"
                                    disabled={isNewTrainingLoading}>
                                {isNewTrainingLoading ? <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : <CreateTrainingButton/>}
                                {isNewTrainingLoading && <span> Creating</span>}
                            </Button>
                        }
                    </ButtonGroup>
                </Form>}
        </ListGroup>
    );

}

export default withFirebase(DashboardCreateTrainingForm);