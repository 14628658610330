import React from 'react';
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import {GrAddCircle} from "react-icons/gr";
import {TiMediaPlay} from "react-icons/ti";
import {GiFinishLine} from "react-icons/gi";
import Countdown from "react-countdown";
import GetHeatScores from "./training-get-heat-scores";
import {FINISH_HEAT} from "../../constants/modal";
import {ModalAlert} from "../Modal/modal-alert";

const CreateHeatButton = (props) => {
    const {permissions} = props;
    return (
        <>
            {permissions &&
                <>
                    <GrAddCircle/> Heat
                </>
            }
        </>
    )
}

const TrainingCountDown = (props) => {
    const {
        training, showCountDown, isNewHeatLoading,
        permissions, running, renderer, currentHeat,
        handleNewHeat, handleStartHeat, handleFinishHeat, handleSeeCompetitor,
        endTime, heatFinished
    } = props;
    return (
        <Card className="pt-3">
            <Card.Header>Current heat
                <Button variant="primary" className="text-left" disabled={running || isNewHeatLoading}
                        style={{float: 'right', margin: '-0.7rem'}}
                        onClick={handleNewHeat}>
                    {isNewHeatLoading ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        : <CreateHeatButton permissions={permissions}/>}
                    {isNewHeatLoading && <span> Creating</span>}
                </Button>
            </Card.Header>
            <Card.Body>
                {showCountDown &&
                    <>
                        <Card.Title>
                            <Row>
                                {permissions && !heatFinished &&
                                    <Col xs={12} md={12}>
                                        <Button variant="primary" onClick={handleStartHeat}
                                                disabled={running}><TiMediaPlay/> Start</Button>
                                        <ModalAlert className="float-end"
                                                    body={FINISH_HEAT} disabled={!running}
                                                    name={<span><GiFinishLine/> Finish</span>}
                                                    handleAction={handleFinishHeat}/>
                                    </Col>
                                }
                                <Col xs={12} md={12} className="countdown-timer vertical-center text-center">
                                    {endTime && <Countdown date={endTime} renderer={renderer}/>}
                                </Col>
                            </Row>
                        </Card.Title>
                        {currentHeat.length !== 0 &&
                            <Row>
                                <Col xs={12} md={12}>
                                    <GetHeatScores training={training} heat={currentHeat} running={running}
                                                   handleSeeCompetitor={handleSeeCompetitor}/>
                                </Col>
                            </Row>
                        }
                    </>
                }
            </Card.Body>
        </Card>
    );
}

export default TrainingCountDown;