import React, {useEffect, useState} from 'react';
import {withFirebase} from "../Firebase";
import ReactFrappeChart from "react-frappe-charts";
import {getSingleSurferChart} from "../Functions/score";

const TrainingGetSurferHeatChart = (props) => {
    const {surferWaves} = props;
    const [loading, setLoading] = useState(false);
    const [scores, setScores] = useState([]);
    const [times, setTimes] = useState([]);

    useEffect(() => {
        getScores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surferWaves]);

    const getScores = async () => {
        await getSingleSurferChart(props.firebase, surferWaves).then(function (total) {
            setLoading(true);
            setScores(total[0]);
            setTimes(total[1]);
        });
    }

    return (
        <div>
            {loading &&
                <div>
                    <ReactFrappeChart
                        title="Points"
                        type="line"
                        colors={["#ffc107"]}
                        axisOptions={{
                            xAxisMode: "tick",
                            yAxisMode: "tick",
                            xIsSeries: 1
                        }}
                        height={250}
                        lineOptions={{dotSize: 8, regionFill: 1}}
                        valuesOverPoints={1}
                        data={{
                            labels: times,
                            datasets: [{values: scores}],
                        }}
                    />
                </div>
            }
        </div>
    );
}

export default withFirebase(TrainingGetSurferHeatChart);