import moment from "moment";
import {onValue, query, orderByChild, equalTo} from "firebase/database";

export async function getSingleSurferChart(firebase, surferWaves) {
    let totalScores = [];
    let totalTimes = [];
    for (const wave of surferWaves) {
        const scores = query(firebase.scores(), orderByChild("waveId"), equalTo(wave.waveId));
        let total = 0;
        await onValue(scores, async (snapshot) => {
            const scoreObjects = snapshot.val();
            if (scoreObjects) {
                Object.keys(scoreObjects).forEach(id => {
                    total = scoreObjects[id].score + total;
                });
                total = total / Object.keys(scoreObjects).length;
                totalScores.push(total);
                totalTimes.push(moment(wave.created).format(`hh:mm a`));
            }
        });

    }
    return [totalScores, totalTimes];
}