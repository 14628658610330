import React from 'react';
import {Container, Row, Col, Card} from "react-bootstrap";
// import all icons
import {GiWaveSurfer} from "react-icons/gi";
import {ImMobile} from "react-icons/im";
import {CgAppleWatch} from "react-icons/cg";
import {MdQueryStats} from "react-icons/md";


const ProductDescription = () => {
    return (
        <div className="product-description-area">
            <Container className="p-1">
                <Row>
                    <Col sm={12} md={3}>
                        <Card className="p-1 text-center bg-transparent">
                            <Card.Title className="product-icon"><ImMobile/></Card.Title>
                            <Card.Body className="product-description-text">
                                Use your phone to design a training, choosing the time, judges, and competitors.
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={3}>
                        <Card className="p-1 text-center bg-transparent">
                            <Card.Title className="product-icon"><GiWaveSurfer/></Card.Title>
                            <Card.Body className="product-description-text">
                                Enjoy a surf session while a person onshore can create a heat and score your waves.
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={3}>
                        <Card className="p-1 text-center bg-transparent">
                            <Card.Title className="product-icon"><CgAppleWatch/></Card.Title>
                            <Card.Body className="product-description-text">
                                With your smartwatch, monitor the time, priorities, and needs in real-time.
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={3}>
                        <Card className="p-1 text-center bg-transparent">
                            <Card.Title className="product-icon"><MdQueryStats/></Card.Title>
                            <Card.Body className="product-description-text">
                                After completion, analyze your data on our statistics page.
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductDescription;
