import React, {useState} from 'react';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import {compose} from "recompose";
import {withFirebase} from "../Firebase";

const Newsletter = (props) => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.elements.email.value;
        const list_id = process.env.REACT_APP_MAILJET_NEWSLETTER_LIST_ID;
        const api_key = process.env.REACT_APP_MAILJET_API_KEY;
        const api_secret = process.env.REACT_APP_MAILJET_API_SECRET;
        const addNewsletter = await props.firebase.cloud('addNewsletter');
        addNewsletter({
            email: email,
            list_id: list_id,
            api_key: api_key,
            api_secret: api_secret
        }).then(() => {
            setFormSubmitted(true);
        }).catch((error) => {
            console.log(error);
        });
    };


    return (
        <div className="smartwatch-area">
            <Container className="smartwatch-area-text">
                <Row>
                    <Col xs={12} md={7}>
                        <div className="newsletter p-4">
                            <h3 className="pb-0">Get the latest news</h3>
                            <p>Sign up for our newsletter to receive the latest news and updates.</p>
                        </div>
                    </Col>
                    <Col xs={12} md={5}>
                        <div className="newsletter p-5">
                            {formSubmitted ? (
                                <h3>Thank you for subscribing!</h3>
                            ) : (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="form-element" controlId="title">
                                            <Row>
                                                <Col xs={7} md={8} className="p-0">
                                                    <Form.Control type="email" name="email"
                                                                  placeholder="Enter your email."
                                                                  required/>
                                                </Col>
                                                <Col xs={5} md={4}>
                                                    <Button variant="primary" type="submit"
                                                            className="w-100">Subscribe</Button>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Form>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const ProductNewsletter = compose(withFirebase)(Newsletter);

export {ProductNewsletter};
