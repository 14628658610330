import React, {useEffect, useState} from 'react';
import {withFirebase} from "../Firebase";
import Loading from "../Views/loading";
import {onValue, query, orderByChild, equalTo} from "firebase/database";

const TrainingGetScore = (props) => {
    const {judge, waveId, surfer, surferWaves} = props;
    const [loading, setLoading] = useState(true);
    const [found, setFound] = useState(false);
    const [score, setScore] = useState(1);

    const getScore = () => {
        const scores = query(props.firebase.scores(), orderByChild("waveId"), equalTo(waveId));
        onValue(scores, snapshot => {
            const scoreObjects = snapshot.val();
            if (scoreObjects) {
                Object.keys(scoreObjects).forEach(id => {
                    if (scoreObjects[id].judge === judge && scoreObjects[id].surfer === surfer) {
                        setScore(scoreObjects[id].score);
                        setFound(true);
                    }
                });
            }
            setLoading(false)
        });
    }

    useEffect(() => {
        getScore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surferWaves]);

    return (
        <>
            {loading && <Loading size="xsm"/>}
            {!found && <div></div>}
            {found && <span key={judge}>{score}</span>}
        </>
    );
}

export default withFirebase(TrainingGetScore);