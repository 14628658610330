import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {convertFromRaw, Editor, EditorState} from "draft-js";
import RichEditor from "./editor";
import AuthUserContext from "../Session/context";
import * as ROLES from "../../constants/roles";
import {withFirebase} from "../Firebase";
import {onValue} from "firebase/database";

/**
 * Source; https://reactrocket.com/post/draft-js-persisting-content/
 */
const Page = (props) => {
    const {pageName} = props;
    const [page, setPage] = useState(EditorState.createEmpty());
    const [loading, setLoading] = useState(true);
    const [hasText, setHasText] = useState(false);

    useEffect(() => {
        getPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPage = () => {
        onValue(props.firebase.page(pageName), snapshot => {
            const pageObject = snapshot.val();
            if (pageObject) {
                const page = EditorState.createWithContent(convertFromRaw(JSON.parse(pageObject.page)));
                const contentState = page.getCurrentContent();
                setHasText(contentState.hasText());
                setPage(page);
            }
        });
        setLoading(false);
    }

    return (
        <Container className="content pt-5 pb-5 min-vh-100">
            {loading && <div>Loading ...</div>}
            {page && hasText &&
                <AuthUserContext.Consumer>
                    {authUser => (authUser && (authUser.role === ROLES.ADMIN || authUser.role === ROLES.EDITOR) ?
                        <WithPermissions page={page} pageName={pageName} /> :
                        <WithoutPermissions page={page}/>)}
                </AuthUserContext.Consumer>
            }
        </Container>
    );
}

export default withFirebase(Page);

export const WithPermissions = (props) => (
    <Tabs defaultActiveKey="view" id="tab">
        <Tab eventKey="view" title="View">
            <Row>
                <Col xs={12} md={12}>
                    <div className="pt-3">
                        <Editor editorState={props.page} readOnly/>
                    </div>
                </Col>
            </Row>
        </Tab>
        <Tab eventKey="edit" title="Edit">
            <Row>
                <Col xs={12} md={12}>
                    <RichEditor editor={props.page} page={props.pageName}/>
                </Col>
            </Row>
        </Tab>
    </Tabs>
);

export const WithoutPermissions = (props) => (
    <Row>
        <Col xs={12} md={12}>
            <div className="pt-3">
                <Editor editorState={props.page} readOnly/>
            </div>
        </Col>
    </Row>
);