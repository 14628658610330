import React, {useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import {IoMdCloseCircle} from "react-icons/io";
import InputRange from "react-input-range";

function MyVerticallyCenteredModal(props) {
    const {handleAction, isNewScoreLoading, submit, ...rest} = props;
    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add score
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputRange
                    formatLabel={value => `${value}`}
                    step={0.5}
                    maxValue={10}
                    minValue={1}
                    value={props.score}
                    onChange={value => handleAction(value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="info"><IoMdCloseCircle/> Close</Button>
                <Button data={props.data} disabled={isNewScoreLoading} variant="primary" onClick={submit}>
                    {props.name}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export var ModalInput = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const {handleAction, name, data, score, submit, isNewScoreLoading} = props
    return (
        <>
            <Button variant="primary" className={props.className}
                    onClick={() => setModalShow(true)}>
                {props.name}
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                name={name}
                data={data}
                score={score}
                isNewScoreLoading={isNewScoreLoading}
                handleAction={handleAction}
                submit={submit}
            />
        </>
    );
};