import React, {useState, useEffect} from 'react';
import {compose} from 'recompose';
import {withFirebase} from "../Firebase";
import {AuthUserContext, withAuthorization, withEmailVerification} from '../Session';
import {Card, Form, Row, Col, ButtonToolbar, ButtonGroup, Button, Spinner} from "react-bootstrap";

const SurferNotifications = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [notification, setNotification] = useState({
        request: false,
        accept: false,
        add: false,
    });

    useEffect(() => {
        const getNotification = props.firebase.cloud('getNotification');
        getNotification().then((result) => {
            if (result.data === null) {
                return;
            }
            setNotification(result.data);
        });
    }, [props.firebase]);

    const handleChange = (event) => {
        setNotification((prevState) => ({
            ...prevState,
            [event.target.id]: !notification[event.target.id],
        }));
    }

    const handleSubmit = (event) => {
        setLoading(true);
        const saveNotification = props.firebase.cloud('saveNotification');
        saveNotification({notification: notification}).then(() => {
            setLoading(false);
        });
        event.preventDefault();
    }

    return (
        <>
            <AuthUserContext.Consumer>
                {(authUser) =>
                    (authUser ?
                        <Card className="pt-3">
                            <Card.Header>Notifications</Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="request">
                                                <Form.Check type="checkbox"
                                                            checked={notification.request}
                                                            label="Email me when I have a new request to join my team"
                                                            onChange={handleChange}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="accept">
                                                <Form.Check type="checkbox"
                                                            checked={notification.accept}
                                                            label="Email me when a member have accepted my request to join my team"
                                                            onChange={handleChange}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="add">
                                                <Form.Check type="checkbox"
                                                            checked={notification.add}
                                                            label="Email me when a member of my team added me to a training"
                                                            onChange={handleChange}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ButtonToolbar aria-label="actions">
                                                <ButtonGroup className="me-2" aria-label="emails">
                                                    <Button variant="primary" type="submit" value="Submit"
                                                            disabled={isLoading}>
                                                        {isLoading ? <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            : 'Save'}
                                                        {isLoading && <span> Saving</span>}
                                                    </Button>
                                                </ButtonGroup>
                                            </ButtonToolbar>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card> :
                        '')
                }
            </AuthUserContext.Consumer>
        </>
    );

}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase,
)(SurferNotifications);