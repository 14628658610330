import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {withRouter} from "../../Functions/router";
import {compose} from 'recompose';
import {PasswordForgetLink} from '../Password/password-forget-form';
import {withFirebase} from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import {Form, Button, Alert} from 'react-bootstrap';
import { getAnalytics, logEvent } from "firebase/analytics";


const INITIAL_STATE = {
    email: '',
    password: '',
    error: null
};

const SignInFormBase = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {
        props.firebase.onAuthUserListener(() => {
            navigate(ROUTES.DASHBOARD)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = event => {
        const {email, password} = state;

        props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                const analytics = getAnalytics();
                logEvent(analytics, 'login', {
                    method: 'email',
                });
                setState({...INITIAL_STATE});
                navigate(ROUTES.DASHBOARD);
            })
            .catch(error => {
                setState({...state, error});
            });

        event.preventDefault();
    };

    const onChange = event => {
        setState({...state, [event.target.name]: event.target.value});
    };

    const {email, password, error} = state;

    const isInvalid = password === '' || email === '';

    return (
        <>
            <Form onSubmit={onSubmit}>
                <Form.Group className="form-element" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        name="email"
                        suggested="email"
                        value={email}
                        onChange={onChange}
                        type="text"
                        placeholder="Email Address"
                        autoComplete="on"
                    />
                </Form.Group>

                <Form.Group className="form-element" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        name="password"
                        suggested="current-password"
                        value={password}
                        onChange={onChange}
                        type="password"
                        placeholder="Password"
                        autoComplete="on"
                    />
                </Form.Group>
                <div className="d-grid gap-2 pb-3">
                    <Button variant="primary" type="submit" disabled={isInvalid}>
                        Sign In
                    </Button>
                </div>
                <PasswordForgetLink/>
                <p>Don't have an account? <Link to={ROUTES.SIGN_UP}>Register here</Link>.</p>
                {error && <Alert variant="danger">{error.message}</Alert>}
            </Form>
        </>
    );
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInForm;