import React from 'react';
import {withFirebase} from '../../Firebase';
import * as ROUTES from "../../../constants/routes";
import {useNavigate} from "react-router-dom";

const SignOut = ({firebase}) => {
    const navigate = useNavigate();

    const handleSignOut = () => {
        firebase.doSignOut();
        navigate(ROUTES.LANDING);
        window.location.reload();
    };

    return (
        <div onClick={handleSignOut} className="nav-link-log-out">
            Sign out
        </div>
    );
}

export default withFirebase(SignOut);