import React, {useEffect, useState} from "react";
import {Card, Col, ListGroup, Row, Table} from "react-bootstrap";
import GetSurfer from "../Surfer/get-surfer";
import moment from "moment";
import GetScore from "./training-get-score";
import GetScoreTotal from "./training-get-score-total";
import TrainingGetSurferHeatChart from "./training-get-surfer-heat-chart";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import {withFirebase} from "../Firebase";
import {onValue, query, orderByChild, equalTo} from "firebase/database";

const StatisticsSurfer = (props) => {
    const {training, heat, surferSelected} = props;
    const [surferWaves, setSurferWaves] = useState([]);

    useEffect(() => {
        getWaves(heat.key);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getWaves = (heatId) => {
        const surferSelectedWaves = query(props.firebase.waves(), orderByChild('heatId'), equalTo(heatId));
        let surfersWavesCollection = [];
        onValue(surferSelectedWaves, snapshot => {
            const surfersWavesObject = snapshot.val();
            if (surfersWavesObject) {
                Object.keys(surfersWavesObject).forEach(key => {
                    surfersWavesCollection.push(surfersWavesObject[key]);
                });
                const surferWaves = surfersWavesCollection.filter(wave => wave.surfer === surferSelected.uid);
                setSurferWaves(surferWaves);
            }
        });
    }

    return (
        <>
            {surferSelected && surferWaves &&
                <Card>
                    <Card.Header>Surfer: {surferSelected.data.name} {surferSelected.data.surname}</Card.Header>
                    <Card.Body>
                        <ListGroup variant="flush">
                            <Row>
                                <Col xs={12} md={3} className="chart-stat">
                                    {surferWaves.length !== 0 &&
                                        <TrainingGetSurferHeatChart surferWaves={surferWaves}
                                                                    surferSelected={surferSelected}/>
                                    }
                                </Col>

                                <Col xs={12} md={9} className="chart-table">
                                    <StatisticsSurferTable surferWaves={surferWaves}
                                                           training={training}
                                                           surferSelected={surferSelected}/>
                                </Col>
                            </Row>
                        </ListGroup>
                    </Card.Body>
                </Card>
            }
        </>
    );
}

export const StatisticsSurferTable = (props) => {
    const {surferWaves, training, surferSelected} = props;
    return (
        <Table striped hover size="sm" className="mt-md-2" responsive>
            <caption>Number of waves: {surferWaves.length}</caption>
            <thead>
            <tr>
                <th>Time</th>
                {training && training.judges && Object.keys(training.judges).map((uid) => (
                    <th key={uid}><GetSurfer uid={uid}/></th>
                ))}
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            {surferWaves.length !== 0 && surferWaves.map((wave) => (
                <tr key={wave.waveId}>
                    <td>{moment(wave.created).format(`hh:mm a`)}</td>
                    {training && training.judges && Object.keys(training.judges).map((uid) => (
                        <td key={uid}>
                            <GetScore judge={uid} waveId={wave.waveId}
                                      surfer={surferSelected.uid}
                                      surferWaves={surferWaves}/>
                        </td>
                    ))}
                    <td>
                        <GetScoreTotal waveId={wave.waveId}
                                       surfer={surferSelected.uid}
                                       surferWaves={surferWaves}/>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(StatisticsSurfer);