import React, {useState, useEffect} from 'react';
import {Button, ButtonGroup, ButtonToolbar, Col, Form, Image, Row, Spinner} from "react-bootstrap";
import ReactCrop from "react-image-crop";
import {withFirebase} from "../Firebase";
import 'react-image-crop/dist/ReactCrop.css';
import {DELETE_USER} from "../../constants/modal";
import {ModalAlert} from "../Modal/modal-alert";
import * as ROLES from "../../constants/roles";
import {AuthUserContext} from "../Session";
import {onValue} from "firebase/database";

const SurferEdit = (props) => {
    const {
        handleChange, surfer, src, crop, handleChangeImage,
        isLoading, handleSubmit, onImageLoaded, onCropComplete, onCropChange,
        uploadImageToStorage, handleDeleteUser
    } = props;
    const [countries, setCountries] = useState([]);
    const [stances, setStances] = useState([]);
    const [jerseys, setJerseys] = useState([]);

    useEffect(() => {
        getCountry();
        getStance();
        getJersey();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getCountry = () => {
        onValue(props.firebase.countries(), (snapshot) => {
            const countriesObject = snapshot.val();
            const countries = Object.keys(countriesObject).map(key => ({
                country: countriesObject[key],
                code: key,
            }));
            // Sort the countries array by the country name
            countries.sort((a, b) => a.country.localeCompare(b.country));
            setCountries(countries);
        });
    }

    const getStance = () => {
        onValue(props.firebase.stances(), (snapshot) => {
            const stancesObject = snapshot.val();
            const stances = Object.keys(stancesObject).map(key => ({
                stance: stancesObject[key],
                code: key,
            }));
            setStances(stances);
        });
    }

    const getJersey = () => {
        onValue(props.firebase.jerseys(), (snapshot) => {
            const jerseysObject = snapshot.val();
            const jerseys = Object.keys(jerseysObject).map(key => ({
                jersey: jerseysObject[key],
                code: key,
            }));
            setJerseys(jerseys);
        });
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group className="form-element" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email"
                                          defaultValue={surfer.email}
                                          disabled={true}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="form-element" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control defaultValue={surfer.name}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="form-element" controlId="middleName">
                            <Form.Label>Middle name</Form.Label>
                            <Form.Control defaultValue={surfer.middleName}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="form-element" controlId="surname">
                            <Form.Label>Surname</Form.Label>
                            <Form.Control defaultValue={surfer.surname}
                                          onChange={handleChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="form-element" controlId="stance">
                            <Form.Label>Stance</Form.Label>
                            <Form.Control as="select"
                                          defaultValue={surfer.stance}
                                          onChange={handleChange}>
                                {stances && stances.map(code => (
                                    <option key={code.code}>{code.stance}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="form-element" controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control as="select"
                                          value={surfer.country}
                                          onChange={handleChange}>
                                {countries && countries.map(code => (
                                    <option key={code.code}>{code.country}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="form-element"
                                    controlId="jerseyColor">
                            <Form.Label>Jersey color</Form.Label>
                            <Form.Control as="select"
                                          value={surfer.jerseyColor}
                                          onChange={handleChange}>
                                {jerseys && jerseys.map(code => (
                                    <option key={code.code}>{code.jersey}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="form-element"
                                    controlId="jerseyNumber">
                            <Form.Label>Jersey number</Form.Label>
                            <Form.Control type="number"
                                          defaultValue={surfer.jerseyNumber}
                                          onChange={handleChange}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="form-element" controlId="dob">
                            <Form.Label>Date of birth</Form.Label>
                            <Form.Control type="date" defaultValue={surfer.dob}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="form-element" controlId="sex">
                            <Form.Label>Sex</Form.Label>
                            <Form.Control as="select"
                                          defaultValue={surfer.sex}
                                          onChange={handleChange}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="form-element" controlId="bio">
                            <Form.Label>Bio</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                          defaultValue={surfer.bio}
                                          onChange={handleChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="form-element" controlId="height">
                            <Form.Label>Height (Cm)</Form.Label>
                            <Form.Control type="number"
                                          defaultValue={surfer.height}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="form-element" controlId="weight">
                            <Form.Label>Weight (Kg)</Form.Label>
                            <Form.Control type="number"
                                          defaultValue={surfer.weight}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col xs={12} md={12} className="p-0">
                                    <Row>
                                        <Col xs={12} md={6} className="p-0">
                                            <Form.Label>Profile picture</Form.Label>
                                            <Form.Control type="file" id="picture"
                                                          label="Picture"
                                                          onChange={handleChangeImage}/>
                                        </Col>
                                        <Col xs={12} md={6} className="pt-3">
                                            {surfer.picture &&
                                                <Image height="100"
                                                       src={surfer.picture}
                                                       rounded
                                                       className="profile-img"/>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={12} className="p-0">
                                    <Row>
                                        <Col xs={12} md={10} className="p-0">
                                            {src && (
                                                <ReactCrop
                                                    src={src}
                                                    crop={crop}
                                                    circularCrop
                                                    locked
                                                    onImageLoaded={onImageLoaded}
                                                    onComplete={onCropComplete}
                                                    onChange={onCropChange}
                                                />
                                            )}
                                        </Col>
                                        <Col xs={12} md={2} className="pt-3">
                                            {src &&
                                                <Button variant="primary" type="submit"
                                                        value="Submit"
                                                        onClick={uploadImageToStorage}>
                                                    Upload
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ButtonToolbar aria-label="actions">
                            <ButtonGroup className="me-2" aria-label="accept group">
                                <Button variant="primary" type="submit" value="Submit"
                                        disabled={isLoading}>
                                    {isLoading ? <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        : 'Click to save'}
                                    {isLoading && <span> Saving</span>}
                                </Button>
                            </ButtonGroup>
                            <AuthUserContext.Consumer>
                                {(authUser) => (authUser && authUser.role !== ROLES.ADMIN ?
                                    <ButtonGroup className="me-2" aria-label="Second group">
                                        <ModalAlert name="Delete account"
                                                    body={DELETE_USER}
                                                    handleAction={() => handleDeleteUser()}/>
                                    </ButtonGroup> : '')}
                            </AuthUserContext.Consumer>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default withFirebase(SurferEdit);