import React from 'react';
import {PasswordForgetForm} from "./password-forget-form";
import {Row, Col, Card} from 'react-bootstrap'
import Container from "react-bootstrap/Container";
import {MdPassword} from "react-icons/md";

const PasswordForget = () => (

    <Container className="content pt-5">
        <Row>
            <Col md={{span: 3, offset: 4}}>
                <Card className="pt-3">
                    <Card.Header><MdPassword/> Reset password</Card.Header>
                    <Card.Body>
                        <PasswordForgetForm/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);

export default PasswordForget;