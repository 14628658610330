import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

const ProductHow = () => {
    return (
        <div className="container-primary-bg">
            <Container>
                <Row className="pb-3">
                    <Col xs={12} md={8} className="pt-5  text-center">
                        <h1>Do you want to use a smartwatch?</h1>
                        <div>
                            <div className="homepage-requirements">
                                <p className="p-list">- Must be compatible with <b>Wear OS and Android, version 7.1.1 or
                                    higher.</b>
                                </p>
                                <p className="p-list">- <b>LTE/4G</b> cellular network connectivity.</p>
                                <p className="p-list">- <b>Water-resistant</b> design to withstand water exposure
                                    during
                                    activities such as swimming
                                    or surfing.</p>
                                <small className="text-muted-requirement">*Please note that our
                                    company
                                    cannot be held responsible for any damages that may occur as a result of
                                    using our application in the water or in any other
                                    circumstances.</small>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="p-5 text-center">
                        <a href="https://play.google.com/store/apps/details?id=com.prosurfingtools.smartwatch"
                           className="google-play-badge" target="_blank" rel="noreferrer">
                            <img alt="google-play-icon" src="/google-play-badge.png"/>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductHow;
