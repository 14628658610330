import React from 'react';
import {Link} from 'react-router-dom';
import {ADMIN, ADMIN_SURFERS, ADMIN_TRAININGS, ADMIN_BOTS, ADMIN_PRODUCTS} from '../../constants/routes';

const AdminSidebar = () => {
    return (
        <div>
            <div className="sidebar-sticky">
                <ul className="flex-column no-style p-0">
                    <li className="nav-item">
                        <Link className="nav-link pl-0" to={ADMIN}>
                            Dashboard
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link pl-0" to={ADMIN_SURFERS}>
                            Users
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link pl-0" to={ADMIN_TRAININGS}>
                            Trainings
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link pl-0" to={ADMIN_BOTS}>
                            Bots
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link pl-0" to={ADMIN_PRODUCTS}>
                            Products
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AdminSidebar;