import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {LANDING} from "../../constants/routes";
import {Link} from "react-router-dom";

export default function NotFound() {
    return (
        <Container className="content pt-5">
            <Row>
                <Col md={{span: 3, offset: 4}}>
                    <Card className="pt-3">
                        <Card.Header>Oops! You seem to be lost.</Card.Header>
                        <Card.Body>
                            <img src="https://media.giphy.com/media/14uQ3cOFteDaU/giphy.gif" width="100%" alt="404"/>
                            <p><a href="https://giphy.com/gifs/404-14uQ3cOFteDaU">via GIPHY</a></p>
                            <p>Not to worry! You can go to our homepage <Link to={LANDING}>here</Link></p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}