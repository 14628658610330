import React, {useState} from 'react';
import {compose} from 'recompose';
import {withFirebase} from "../Firebase";
import {withAuthorization, withEmailVerification} from '../Session';
import {getFunctions, httpsCallable} from "firebase/functions";
import {Button} from "react-bootstrap";
import {GrStripe} from "react-icons/gr";

const CustomerPortal = (props) => {
    const [loading, setLoading] = useState(false);

    const goToCustomerPortal = async () => {
        setLoading(true);
        const functions = getFunctions();
        const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        const {data} = await functionRef({
            returnUrl: window.location.origin,
            locale: "auto", // Optional, defaults to "auto"
        });
        // Redirect to the portal in another tab.
        window.open(data.url, '_blank');
        setLoading(false);
    }

    return (
        <Button onClick={goToCustomerPortal} variant="primary" disabled={loading}><GrStripe/> Stripe Customer Portal</Button>
    );

}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase,
)(CustomerPortal);