import React from 'react';
import Navigation from "../Navigation";

export default function ButtonAppBar() {
    return (
        <div>
            <Navigation />
        </div>
    );
}
