import React, {useEffect, useState} from 'react';
import {compose} from 'recompose';
import {withFirebase} from "../Firebase";
import {withAuthorization, withEmailVerification} from '../Session';
import {Container, Row, Col, Card} from "react-bootstrap";
import SurferCard from "./surfer-card";
import PasswordChangeForm from "../Account/Password/password-change-form";
import SurferEdit from "./surfer-edit";
import {useNavigate} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import moment from "moment";
import SurferStripe from "./surfer-stripe";
import SurferNotifications from "./surfer-notifications";
import {onValue} from "firebase/database";
import {getDownloadURL, uploadBytesResumable} from 'firebase/storage';

const Surfer = (props) => {
    const navigate = useNavigate();
    const [uid] = useState(props.router.params.uid ? props.router.params.uid : props.uid);
    const [loading, setLoading] = useState(false);
    const [surfer, setSurfer] = useState({
        bio: '', bot: '', country: '',
        created: '', dob: '', email: '',
        height: '', jerseyColor: '', jerseyNumber: '',
        middleName: '', name: '', picture: '',
        role: '', sex: '', stance: '', surname: '',
        updated: '', weight: '', team: [],
    });
    const [bot, setBot] = useState(false);
    const [src, setSrc] = useState(null);
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [crop, setCrop] = useState({
        unit: 'px', // default, can be 'px' or '%'
        x: 50,
        y: 50,
        width: 120,
        height: 120,
    });

    useEffect(() => {
        getSurferData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSurferData = () => {
        if (uid) {
            onValue(props.firebase.surfer(uid), snapshot => {
                const surfer = snapshot.val();
                if (surfer) {
                    setSurfer(surfer);
                }
            });
            onValue(props.firebase.bot(uid), snapshot => {
                const bot = snapshot.val();
                if (bot) {
                    setSurfer(bot);
                    setBot(true);
                }
            });
        }
    }

    const handleChange = (event) => {
        setSurfer((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
        }));
    }

    const updateSurferData = (key, value) => {
        setSurfer((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    }

    const handleSubmit = (event) => {
        let type = bot ? 'bot' : 'surfer';
        setLoading(true);
        const updateSurfer = props.firebase.cloud('updateSurfer');
        updateSurfer({uid: uid, surfer: surfer, type: type}).then(() => {
            setLoading(false);
        });
        event.preventDefault();
    }

    const handleDeleteUser = () => {
        const deleteSurfer = props.firebase.cloud('deleteSurfer');
        deleteSurfer({surfer: surfer}).then(() => {
            const user = props.firebase.currentUser();
            user.delete().then(() => {
                navigate(ROUTES.LANDING);
                props.firebase.doSignOut();
                window.location.reload();
            }).catch((error) => {
                console.log(error);
            });
        });
    }

    const handleChangeImage = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => setSrc(reader.result));
            reader.readAsDataURL(file);
        }
        event.preventDefault();
    }

    const onImageLoaded = (image) => {
        setImage(image);
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (image && crop.width && crop.height) {
            const file = await getCroppedImg(
                image,
                crop,
                'newFile.jpeg'
            );
            setFile(file);
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                const file = new File([blob], uid, {type: 'image/jpeg'});
                resolve(file);
            }, 'image/jpeg');
        });
    }

    const uploadImageToStorage = () => {
        const metadata = {contentType: 'image/jpeg'};
        const imageRef = props.firebase.uploadImage(file.name);
        const uploadTask = uploadBytesResumable(imageRef, file, metadata);
        uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        break;
                    case 'running':
                        break;
                    default:
                        break;
                }
            }, (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default:
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(imageRef).then((downloadURL) => {
                    updateSurferData('picture', downloadURL);
                    setSrc(null);
                });
            }
        );
    }

    return (
        <>
            {surfer.created &&
                <Container className="content p-2 h-100">
                    <Row>
                        <Col xs={12} md={3}>
                            <SurferCard surfer={surfer} uid={uid} footer={1} displayUid={1}/>
                            <SurferStripe/>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card className="pt-3">
                                <Card.Header>Edit
                                    <div className="float-end">
                                        <small className="text-muted">Created: {moment(surfer.created).fromNow()} | Last
                                            updated {moment(surfer.updated).fromNow()}</small>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <SurferEdit surfer={surfer}
                                                handleChange={handleChange}
                                                handleSubmit={handleSubmit}
                                                crop={crop}
                                                src={src}
                                                handleChangeImage={handleChangeImage}
                                                handleDeleteUser={() => handleDeleteUser()}
                                                onImageLoaded={onImageLoaded}
                                                onCropComplete={onCropComplete}
                                                onCropChange={onCropChange}
                                                uploadImageToStorage={uploadImageToStorage}
                                                isLoading={loading}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={3}>
                            {!bot &&
                                <Card className="pt-3">
                                    <Card.Header>Change password</Card.Header>
                                    <Card.Body>
                                        <PasswordChangeForm/>
                                    </Card.Body>
                                </Card>
                            }
                            <SurferNotifications/>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );

}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase,
)(Surfer);