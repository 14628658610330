import React, {useState} from 'react';
import {Form, Button, Row, Col} from 'react-bootstrap';
import {withFirebase} from '../../Firebase';

const PasswordChangeForm = (props) => {
    const [state, setState] = useState({
        passwordOne: '',
        passwordTwo: '',
        error: null,
    });

    const onSubmit = event => {
        const {passwordOne} = state;

        props.firebase
            .doPasswordUpdate(passwordOne)
            .then(() => {
                setState({...state});
            })
            .catch(error => {
                setState({...state, error});
            });

        event.preventDefault();
    };

    const onChange = event => {
        setState({...state, [event.target.name]: event.target.value});
    };

    const {passwordOne, passwordTwo, error} = state;

    const isInvalid =
        passwordOne !== passwordTwo || passwordOne === '';

    return (
        <Form className="mb-3" onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group className="form-element" controlId="passwordOne">
                        <Form.Control
                            name="passwordOne"
                            value={passwordOne}
                            onChange={onChange}
                            type="password"
                            autoComplete="new-password"
                            placeholder="New Password"
                        />
                    </Form.Group>
                    <Form.Group className="form-element" controlId="passwordTwo">
                        <Form.Control
                            name="passwordTwo"
                            value={passwordTwo}
                            onChange={onChange}
                            type="password"
                            autoComplete="new-password"
                            placeholder="Confirm New Password"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid gap-2">
                        <Button disabled={isInvalid} variant="primary" type="submit">
                            Change Password
                        </Button>
                    </div>
                </Col>
            </Row>
            {error && <p>{error.message}</p>}
        </Form>
    );
}


export default withFirebase(PasswordChangeForm);