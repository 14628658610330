import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {withFirebase} from "../Firebase";
import {Button, Container, Row, Col} from "react-bootstrap";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import Loading from "../Views/loading";
import {Link} from "react-router-dom";
import StatisticsDetails from "./statistics-details";
import StatisticsHeats from "./statistics-heats";
import {useReactToPrint} from 'react-to-print';
import {FaFileExport} from "react-icons/fa";

const getPageMargins = () => {
    const marginTop = 10;
    const marginRight = 10;
    const marginBottom = 0;
    const marginLeft = 10;
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

const Statistics = (props) => {
    const [currentUserRole] = useState(props.firebase.currentSurferData().role);
    const [trainingId] = useState(props.router.params.id);
    const [training, setTraining] = useState(null);
    const [heats, setHeats] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTraining().then(() => {
            getHeats(trainingId);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTraining = () => {
        const data = ({trainingId: trainingId, surferRole: currentUserRole});
        const getTrainingById = props.firebase.cloud('getTrainingById');
        getTrainingById(data).then(result => {
            if (result.data) {
                setTraining(result.data);
            }
        });
        return Promise.resolve();
    }

    const getHeats = (id) => {
        const getHeats = props.firebase.cloud('getHeats');
        getHeats({trainingId: id}).then(result => {
            if (result.data) {
                setHeats(result.data);
            }
        });
        setLoading(false);
    }

    return (
        <Container className="content p-2 h-100">
            {loading && <Loading/>}
            <Row>
                <Col>
                    <Link to={`/dashboard/training/${trainingId}`}>Go to training</Link>
                </Col>
                <Col className="">
                    <small className="text-muted float-end">Statistics for training: {trainingId}</small>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3">
                    {training && training.author &&
                        <ExportPdf training={training} heats={heats}/>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export const StatisticsData = (props) => {
    const {training, heats} = props;
    return (
        <>
            <StatisticsDetails training={training}/>
            <StatisticsHeats heats={heats} training={training}/>
        </>
    );
}

export const ExportPdf = (props) => {
    const {training, heats} = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        documentTitle: `Statistics for training: ${training.trainingId}`,
        copyStyles: true,
        pageStyle: getPageMargins(),
        content: () => {
            const tableStat = componentRef.current.cloneNode(true);
            const PrintElem = document.createElement('div');
            PrintElem.innerHTML = `<a class="navbar-brand-pro brand navbar-brand" href="/">prosurfing<span class="brand-v">tools</span></a>`;
            PrintElem.appendChild(tableStat);
            return PrintElem;
        },
    });

    return (
        <div>
            <Button onClick={handlePrint} variant="primary"><FaFileExport/> Export to PDF</Button>
            <MyInput ref={componentRef} training={training} heats={heats}/>
        </div>
    );
};

const MyInput = forwardRef(function MyInput(props, ref) {
    const {training, heats} = props;
    return (
        <div ref={ref}>
            <style>{getPageMargins()}</style>
            <StatisticsData training={training} heats={heats}/>
        </div>
    );
});

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(Statistics);