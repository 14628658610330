import React, {useEffect, useState} from 'react';
import {Col, Container} from "react-bootstrap";
import AdminSidebar from "./admin-sidebar";
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import {withFirebase} from "../Firebase";
import TrainingListFormPagination from "./admin-trainings-pagination";
import {onValue, query} from "firebase/database";

const TrainingList = (props) => {
    return (
        <Container className="content  min-vh-100">
            <div className="row pt-3">
                <Col sm={12} md={2}>
                    <AdminSidebar/>
                </Col>
                <Col sm={12} md={10}>
                    <TrainingListForm {...props}/>
                </Col>
            </div>
        </Container>
    )
}

const TrainingListForm = (props) => {
    const [trainings, setTrainings] = useState([]);
    const [loading, setLoading] = useState(false);
    const firebaseRef = props.firebase.trainings();

    useEffect(() => {
        onValue(query(firebaseRef), onFirebaseValueChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onListenForTrainings = () => {
        setLoading(true);
        onValue(firebaseRef, snapshot => {
            const trainingsObject = snapshot.val();
            if (trainingsObject) {
                const trainingsList = Object.keys(trainingsObject).map(key => ({
                    ...trainingsObject[key],
                    key: key,
                }));
                setTrainings(trainingsList);
            }
            setLoading(false);
        });
    }

    const onFirebaseValueChanged = () => {
        onListenForTrainings();
    }

    const handleDeleteTraining = (trainingId) => {
        const deleteTraining = this.props.firebase.cloud('deleteTraining');
        deleteTraining({trainingId: trainingId});
    }

    return (
        <TrainingListFormPagination trainings={trainings} handleDeleteTrainings={handleDeleteTraining}
                                    loading={loading}/>
    );

}

const condition = authUser =>
    authUser && authUser.role === ROLES.ADMIN

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(TrainingList);


