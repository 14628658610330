import React, {useEffect, useState} from 'react';
import {withFirebase} from "../Firebase";
import * as ROLES from "../../constants/roles";
import AuthUserContext from "../Session/context";
import {Link} from "react-router-dom";
import {onValue} from "firebase/database";

const GetSurfer = (props) => {
    const [surferUid, setSurferUid] = useState(null);
    const [surferName, setSurferName] = useState(null);
    const [surferSurname, setSurferSurname] = useState(null);
    const [surferEmail, setSurferEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uid] = useState(props.uid);
    const [email] = useState(props.email ? props.email : null);

    useEffect(() => {
        setLoading(true);
        // Check if the uid belongs to a surfer
        setSurferUid(uid);
        onValue(props.firebase.surferName(uid), snapshot => {
            const name = snapshot.val();
            if (name) {
                setSurferName(name);
            }
        });
        onValue(props.firebase.surferSurname(uid), snapshot => {
            const surname = snapshot.val();
            if (surname) {
                setSurferSurname(surname);
            }
        });
        onValue(props.firebase.surferEmail(uid), snapshot => {
            const email = snapshot.val();
            if (email) {
                setSurferEmail(email);
            }
        });


        // Check if the uid belongs to a bot
        onValue(props.firebase.botName(uid), snapshot => {
            const botName = snapshot.val();
            if (botName) {
                setSurferName(botName);
            }
        });
        onValue(props.firebase.botSurname(uid), snapshot => {
            const botSurname = snapshot.val();
            if (botSurname) {
                setSurferSurname(botSurname);
            }
        });
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);

    return (
        <>
            {loading && <div>Loading...</div>}
            {surferUid && !email &&
                <AuthUserContext.Consumer>
                    {authUser => (authUser && (authUser.role === ROLES.ADMIN) ?
                        <WithPermissionsName name={surferName} surname={surferSurname} uid={surferUid}/> :
                        <WithoutPermissionsName name={surferName} surname={surferSurname} uid={surferUid}/>)}
                </AuthUserContext.Consumer>
            }
            {surferUid && email &&
                <AuthUserContext.Consumer>
                    {authUser => (authUser && (authUser.role === ROLES.ADMIN) ?
                        <WithPermissionsEmail email={surferEmail} uid={surferUid}/> :
                        <WithoutPermissionsEmail email={surferEmail} uid={surferUid}/>)}
                </AuthUserContext.Consumer>
            }
        </>
    );
}

const WithPermissionsName = (props) => (
    <Link to={'/admin/surfer/' + props.uid}>
        <span key={props.uid}>{props.name} {props.surname}</span>
    </Link>
);

const WithoutPermissionsName = (props) => (
    <span key={props.uid}>{props.name} {props.surname}</span>
);

const WithPermissionsEmail = (props) => (
    <Link to={'/admin/surfer/' + props.uid}>
        <span key={props.uid}>{props.email}</span>
    </Link>
);

const WithoutPermissionsEmail = (props) => (
    <span key={props.uid}>{props.email}</span>
);

export default withFirebase(GetSurfer);