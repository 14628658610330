import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import LandingPage from '../Landing';
import SignUp from '../Account/Sign/sign-up';
import SignIn from '../Account/Sign/sign-in';
import PasswordForget from '../Account/Password/password-forget';
import HomePage from '../Dashboard';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import DashboardTraining from '../Dashboard/training';
import TeamPage from '../Team/index';
import Footer from '../Views/footer';
import * as ROUTES from '../../constants/routes';
import {withAuthentication} from '../Session';
import PrimarySearchAppBar from '../Views/navigation-bar';
import Privacy from "../Page/privacy";
import Terms from "../Page/terms";
import Cookie from "../Page/cookie";
import NonDiscrimination from "../Page/non-discrimination";
import SurferList from "../Admin/admin-surfers";
import BotsList from "../Admin/admin-bots";
import TrainingList from "../Admin/admin-trainings";
import ScrollToTop from "../Navigation/scroll-to-top";
import NotFound from "../Page/not-found";
import ProductsList from "../Admin/admin-products";
import Product from "../Stripe/product";
import Subscribe from "../Stripe/subscribe";
import Statistics from "../Dashboard/statistics";

const App = () => (
    <BrowserRouter>
        <ScrollToTop/>
        <PrimarySearchAppBar/>
        <Routes>
            <Route path='*' element={<NotFound/>}/>
            <Route path={ROUTES.LANDING} element={<LandingPage/>}/>
            <Route path={ROUTES.SIGN_UP} element={<SignUp/>}/>
            <Route path={ROUTES.SIGN_IN} element={<SignIn/>}/>
            <Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForget/>}/>
            <Route path={ROUTES.DASHBOARD} element={<HomePage/>}/>
            <Route path={ROUTES.ACCOUNT} element={<AccountPage/>}/>
            <Route path={ROUTES.ADMIN} element={<AdminPage/>}/>
            <Route path={ROUTES.ADMIN_SURFERS} element={<SurferList/>}/>
            <Route path={ROUTES.ADMIN_TRAININGS} element={<TrainingList/>}/>
            <Route path={ROUTES.ADMIN_BOTS} element={<BotsList/>}/>
            <Route path={ROUTES.ADMIN_PRODUCTS} element={<ProductsList/>}/>
            <Route path={ROUTES.ADMIN_PRODUCT} element={<Product/>}/>
            <Route path={ROUTES.ADMIN_SURFER} element={<AccountPage/>}/>
            <Route path={ROUTES.ADMIN_BOT} element={<AccountPage/>}/>
            <Route path={ROUTES.ADMIN_TRAINING} element={<DashboardTraining/>}/>
            <Route path={ROUTES.ADMIN_TRAINING_STATISTICS} element={<Statistics/>}/>
            <Route path={ROUTES.DASHBOARD_TRAINING} element={<DashboardTraining/>}/>
            <Route path={ROUTES.DASHBOARD_TRAINING_STATISTICS} element={<Statistics/>}/>
            <Route path={ROUTES.SUBSCRIBE} element={<Subscribe/>}/>
            <Route path={ROUTES.TEAM} element={<TeamPage/>}/>
            <Route path={ROUTES.PRIVACY_POLICY} element={<Privacy/>}/>
            <Route path={ROUTES.COOKIE_POLICY} element={<Cookie/>}/>
            <Route path={ROUTES.NON_DISCRIMINATION} element={<NonDiscrimination/>}/>
            <Route path={ROUTES.TERMS} element={<Terms/>}/>
        </Routes>
        <Footer/>
    </BrowserRouter>
);

export default withAuthentication(App);
