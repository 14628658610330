import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import moment from "moment";
import {Link} from "react-router-dom";
import {ButtonToolbar, ButtonGroup, Pagination} from "react-bootstrap";
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import {withFirebase} from "../Firebase";
import {BsFolderSymlinkFill} from "react-icons/bs";

const SurfersListFormPagination = (props) => {
    const {surfers, loading} = props;
    const rows = surfers.length;
    const rowsPerPage = 5;
    const lastPage = Math.ceil(rows / rowsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setData(surfers.slice(0, rowsPerPage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surfers]);

    useEffect(() => {
        displayPaginationTrainings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleClick = (page) => {
        setCurrentPage(page);
        const pageIndex = page - 1;
        const firstIndex = pageIndex * rowsPerPage;
        const lastIndex = pageIndex * rowsPerPage + rowsPerPage;
        setData(surfers.slice(firstIndex, lastIndex));
    };

    const displayPaginationTrainings = () => {
        let links = [];
        for (let number = 1; number <= lastPage; number++) {
            links.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handleClick(number)}>
                    {number}
                </Pagination.Item>,
            );

        }
        setItems(links);
    }

    return (
        <>
            <h1>Dashboard | Users</h1>
            {loading && <div>Loading ...</div>}
            {data &&
                <Table striped hover size="sm" responsive>
                    <thead>
                    <tr>
                        <th>Full name</th>
                        <th>Email</th>
                        <th>Created</th>
                        <th>Last Updated</th>
                        <th>Team Members</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(surfer => (
                        <tr key={surfer.uid}>
                            <td>{surfer.name} {surfer.middleName} {surfer.surname}</td>
                            <td>{surfer.email}</td>
                            <td>{moment(surfer.created).fromNow()}</td>
                            <td>{moment(surfer.updated).fromNow()}</td>
                            <td><b>{surfer.members ?? 0}</b> people in their team</td>
                            <td>{surfer.role}</td>
                            <td>
                                <ButtonToolbar aria-label="actions">
                                    <ButtonGroup className="me-2" aria-label="accept group">
                                        <Link to={'/admin/surfer/' + surfer.uid}
                                              className="btn btn-primary">
                                            <BsFolderSymlinkFill/>
                                        </Link>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }
            {items.length > 1 &&
                <Pagination>
                    {items}
                </Pagination>
            }
        </>
    );
}

const condition = authUser =>
    authUser && authUser.role === ROLES.ADMIN

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(SurfersListFormPagination);