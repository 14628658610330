import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Card} from "react-bootstrap";
import moment from "moment";
import NewsImage from "./news-image";

const News = (props) => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        fetchArticles();
    }, []);

    const fetchArticles = async () => {
        try {
            const response = await fetch('https://blog.prosurfingtools.com/wp-json/wp/v2/posts?per_page=2');
            const data = await response.json();
            setArticles(data);
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    return (
        <>
            {articles && articles.length > 0 &&
                <div className="news-area">
                    <Container className="pt-5 pb-5">
                        <Row>
                            {articles && articles.map((article, i) => (
                                <Col xs={12} md={6} key={i} className="p-4 news-card">
                                    <a href={article.link} target="_blank" rel="noreferrer" className="news-card-link">
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <NewsImage id={article.featured_media}/>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Card className="news-card-article">
                                                    <Card.Header>{article.title.rendered}</Card.Header>
                                                    <Card.Body>
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: article.excerpt.rendered}}/>
                                                    </Card.Body>
                                                    {moment(article.date).format(`D MMMM YYYY`)}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </a>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            }
        </>
    );
}

export default News;
