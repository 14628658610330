import React from "react";
import {Spinner} from "react-bootstrap";

const Loading = (props) => {
    const {size} = props;
    const loadingSize = size ? 'loader-' + size : '';
    return (
        <div className={'loader ' + loadingSize}>
            <Spinner animation="border">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

export default Loading;