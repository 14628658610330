import {initializeApp} from "firebase/app";
import {connectFirestoreEmulator, Firestore, getFirestore, getDocs, collection, doc} from "firebase/firestore";
import {getDatabase, connectDatabaseEmulator, onValue, ref as databaseRef, push, child} from "firebase/database";
import { getStorage, ref as storageRef } from "firebase/storage";
import {
    Auth,
    connectAuthEmulator,
    getAuth,
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
    getRedirectResult,
    fetchSignInMethodsForEmail,
    linkWithCredential,
    updatePassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence
} from "firebase/auth";
import {httpsCallable, connectFunctionsEmulator, getFunctions} from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import {getStripePayments} from "@stripe/firestore-stripe-payments";

require('dotenv').config()

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Modular API.
const firebaseApp = initializeApp(config);
const auth: Auth = getAuth(firebaseApp);
const firestore: Firestore = getFirestore(firebaseApp);
const database = getDatabase(firebaseApp);
const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);
const provider = new GoogleAuthProvider();

class Firebase {
    constructor() {
        this.authSurfer = null;

        // *** firestore-stripe-payments API ***
        this.payments = getStripePayments(firebaseApp, {
            productsCollection: "products",
            customersCollection: "customers",
            pricesCollection: "prices",
            subscriptionsCollection: "subscriptions",
        });

        // Production product and price
        this.product = 'prod_NKcdGmVxh3yF6O';
        this.price = 'price_1MreLaIPR8sY7dy0F2GtaMvI';

        // eslint-disable-next-line no-restricted-globals
        if (location.hostname === "localhost") {

            // Test product and price
            this.product = 'prod_NKfp9ElsQqJq9U';
            this.price = 'price_1MrehsIPR8sY7dy0bE0euiSN';

            connectDatabaseEmulator(database, "localhost", 9002);
            connectFunctionsEmulator(functions, "localhost", 5001);
            connectFirestoreEmulator(firestore, "localhost", 8080);
            connectAuthEmulator(auth, "http://localhost:9099", {
                disableWarnings: true,
            });
        } else {
            getAnalytics(firebaseApp);
        }
    }

    // *** Merge Auth and DB User API ***
    onAuthUserListener = (next, fallback) =>
        onAuthStateChanged(auth, authUser => {
            if (authUser) {
                getDocs(this.subscriptions(authUser.uid)).then((querySnapshot) => {
                    const subscriptions = [];
                    querySnapshot.forEach((doc) => {
                        if (doc.data().status === "active" || doc.data().status === "trialing") {
                            subscriptions.push(doc.data().status);
                        }
                    });
                    return subscriptions;
                }).then((subscriptions) => {
                    const subscription = subscriptions[0];
                    onValue(this.surfer(authUser.uid), (snapshot) => {
                        const dbSurfer = snapshot.val();
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            subscription: subscription,
                            ...dbSurfer,
                        };
                        this.authSurfer = authUser;
                        next(authUser);
                    });
                });
            }
        });
    // Do not close onAuthUserListener with {}, otherwise the user will be logged out, and it will need to refresh the page.

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        createUserWithEmailAndPassword(auth, email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        setPersistence(auth, browserSessionPersistence).then(() => {
            return signInWithEmailAndPassword(auth, email, password);
        });

    doSendEmailVerification = () => sendEmailVerification(auth.currentUser, {
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

    doSignInWithGoogle = () => {
        return setPersistence(auth, browserSessionPersistence).then(async () => {
            return await signInWithPopup(auth, provider);
        });
    };

    getRedirectResult = () => {
        return getRedirectResult(auth);
    };

    fetchSignInMethodsForEmail = (email) => {
        return fetchSignInMethodsForEmail(auth, email);
    };

    signInWithEmailAndPassword = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    linkWithCredential = (user, credential) => {
        return linkWithCredential(user, credential);
    };

    doSignOut = () => signOut(auth);

    doPasswordReset = email => sendPasswordResetEmail(auth, email);

    doPasswordUpdate = password =>
        updatePassword(auth.currentUser, password);

    currentUser = () => auth.currentUser;

    currentSurferData = () => this.authSurfer;

    // *** Cloud Functions ***
    cloud = functionName => httpsCallable(functions, functionName);

    // *** Stripe API ***
    productId = () => this.product;
    priceId = () => this.price;

    bot = uid => databaseRef(database, `bot/${uid}`);
    bots = () => databaseRef(database, 'bot');
    botName = uid => databaseRef(database, `bot/${uid}/name`);
    botSurname = uid => databaseRef(database, `bot/${uid}/surname`);

    surfer = uid => databaseRef(database, `surfer/${uid}`);
    surferName = uid => databaseRef(database, `surfer/${uid}/name`);
    surferSurname = uid => databaseRef(database, `surfer/${uid}/surname`);
    surferEmail = uid => databaseRef(database, `surfer/${uid}/email`);
    surfers = () => databaseRef(database, 'surfer');
    surferTeam = uid => databaseRef(database, `team/${uid}`);
    surferTeamMember = (uid, member) => databaseRef(database, `team/${uid}/${member}`);

    heat = id => databaseRef(database, `heat/${id}`);
    heats = () => databaseRef(database, 'heat');
    createHeat = () => push(child(databaseRef(database), 'heat')).key;

    wave = id => databaseRef(database, `wave/${id}`);
    waves = () => databaseRef(database, 'wave');
    createWave = () => push(child(databaseRef(database), 'wave')).key;

    score = (id) => databaseRef(database, `score/${id}`);
    scores = () => databaseRef(database, `score`);
    createScore = () => push(child(databaseRef(database), 'score')).key;

    message = (id) => databaseRef(database, `message/${id}`);
    messages = () => databaseRef(database, `message`);
    createMessage = () => push(child(databaseRef(database), 'message')).key;

    training = id => databaseRef(database, `training/${id}`);
    trainings = () => databaseRef(database, 'training');
    createTraining = () => push(child(databaseRef(database), 'training')).key;
    trainingJudges = uid => databaseRef(database, `training/${uid}/judges`);
    trainingSurfers = uid => databaseRef(database, `training/${uid}/surfers`);
    trainingTeamJudge = (uid, member) => databaseRef(database, `training/${uid}/judges/${member}`);
    trainingTeamSurfer = (uid, member) => databaseRef(database, `training/${uid}/surfers/${member}`);

    country = id => databaseRef(database, `country/${id}`);
    countries = () => databaseRef(database, 'country');

    stances = () => databaseRef(database, 'stance');
    jerseys = () => databaseRef(database, 'jersey');

    page = id => databaseRef(database, `page/${id}`);
    pages = () => databaseRef(database, 'page');

    customer = (uid) => doc(collection(firestore, 'customers'), uid);
    subscriptions = (uid) => collection(doc(firestore, 'customers', uid), 'subscriptions');

    uploadImage = name => storageRef(storage, `images/${name}`);
}

export default Firebase;