import React from "react";
import {Table, Accordion, ListGroup} from "react-bootstrap";
import moment from "moment";
import GetSurfer from "../Surfer/get-surfer";

const TrainingDetails = (props) => {
    const {training} = props;
    return (
        <Accordion className="pt-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Details</Accordion.Header>
                <Accordion.Body>
                    <ListGroup variant="flush">
                        <Table className="table" size="sm">
                            <tbody>
                            <tr>
                                <th scope="row">Title</th>
                                <td>{training.title}</td>
                            </tr>
                            <tr>
                                <th scope="row">Created</th>
                                <td>{moment(training.created).format('LLL')}</td>
                            </tr>
                            <tr>
                                <th scope="row">Time</th>
                                <td>{training.trainingTime} minutes</td>
                            </tr>
                            <tr>
                                <th scope="row">Author</th>
                                <td><GetSurfer uid={training.author}/></td>
                            </tr>
                            <tr>
                                <th scope="row">Judges</th>
                                <td>{training.judges && Object.keys(training.judges).map((uid, i) => (
                                    <li key={uid + '-judge'}><GetSurfer key={uid} uid={uid}/></li>
                                ))}</td>
                            </tr>
                            <tr>
                                <th scope="row">Competitors</th>
                                <td>{training.surfers && Object.keys(training.surfers).map((uid, i) => (
                                    <li key={uid + '-surfer'}><GetSurfer key={uid} uid={uid}/></li>
                                ))}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default TrainingDetails;