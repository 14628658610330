import React, {useEffect, useState} from 'react';
import {compose} from 'recompose';
import {withFirebase} from "../Firebase";
import {withAuthorization, withEmailVerification} from '../Session';
import * as ROLES from '../../constants/roles';
import {Row, Col, Card, Container} from 'react-bootstrap';
import AdminSidebar from "./admin-sidebar";
import {getProducts} from "@stripe/firestore-stripe-payments";
import {onValue} from "firebase/database";

const Dashboard = (props) => {
    return (
        <>
            <h1>Dashboard</h1>
            <Row>
                <DashboardNumberCard surfers={props.surfers} source="surfers"/>
                <DashboardNumberCard surfers={props.bots} source="bots"/>
                <DashboardNumberCard surfers={props.trainings} source="trainings"/>
                <DashboardNumberCard surfers={props.heats} source="heats"/>
                <DashboardNumberCard surfers={props.products} source="products"/>
            </Row>
        </>
    );
};

const DashboardNumberCard = (props) => {
    return (
        <Col>
            <Card className={'mb-2 text-center dashboard-card dashboard-' + props.source}>
                <Card.Body>
                    <div className="dashboard-number">
                        {props.surfers}
                    </div>
                    <h3>{props.source}</h3>
                </Card.Body>
            </Card>
        </Col>
    )
}

const Admin = (props) => {
    const [loading, setLoading] = useState(true);
    const [surfers, setSurfers] = useState([]);
    const [bots, setBots] = useState([]);
    const [trainings, setTrainings] = useState([]);
    const [heats, setHeats] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getNumberSurfers();
        getNumberBots();
        getNumberTrainings();
        getNumberHeats();
        getNumberProducts().then((products) => {
            setProducts(products.length);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNumberSurfers = () => {
        onValue(props.firebase.surfers(), snapshot => {
            const surfersObject = snapshot.val();
            if (surfersObject) {
                const surfersList = Object.keys(surfersObject).map(key => ({
                    ...surfersObject[key],
                    uid: key,
                }));
                setSurfers(surfersList.length);
            }
            setLoading(false);
        });
    };

    const getNumberBots = () => {
        onValue(props.firebase.bots(), snapshot => {
            const botsObject = snapshot.val();
            if (botsObject) {
                const botsList = Object.keys(botsObject).map(key => ({
                    ...botsObject[key],
                    uid: key,
                }));
                setBots(botsList.length);
            }
            setLoading(false);
        });
    };

    const getNumberTrainings = () => {
        onValue(props.firebase.trainings(), snapshot => {
            const trainingsObject = snapshot.val();
            if (trainingsObject) {
                const trainingsList = Object.keys(trainingsObject).map(key => ({
                    ...trainingsObject[key],
                    uid: key,
                }));
                setTrainings(trainingsList.length);
            }
            setLoading(false);
        });
    };

    const getNumberHeats = () => {
        onValue(props.firebase.heats(), snapshot => {
            const heatsObject = snapshot.val();
            if (heatsObject) {
                const heatsList = Object.keys(heatsObject).map(key => ({
                    ...heatsObject[key],
                    uid: key,
                }));
                setHeats(heatsList.length);
            }
            setLoading(false);
        });
    };

    const getNumberProducts = async () => {
        return await getProducts(props.firebase.payments, {
            includePrices: true,
            activeOnly: true,
        });
    }

    return (
        <Container className="content min-vh-100">
            <div className="row pt-3">
                <Col sm={12} md={2}>
                    <AdminSidebar/>
                </Col>
                <Col sm={12} md={10}>
                    {loading && <div>Loading ...</div>}
                    <Dashboard surfers={surfers} bots={bots} trainings={trainings} products={products} heats={heats}/>
                </Col>
            </div>
        </Container>
    )
}

const condition = authUser =>
    authUser && authUser.role === ROLES.ADMIN

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(Admin);
