import React, {useState, useEffect} from 'react';
import {Card} from "react-bootstrap";

const NewsImage = (props) => {
    const {id} = props;
    const [imageLink, setImageLink] = useState([]);

    useEffect(() => {
        fetchImageLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchImageLink = async () => {
        try {
            const response = await fetch('https://blog.prosurfingtools.com/wp-json/wp/v2/media/' + id);
            const data = await response.json();
            setImageLink(data.source_url);
        } catch (error) {
            console.error('Error fetching image link:', error);
        }
    };

    return (
        <Card.Img variant="top" src={imageLink} width="50%" className="pt-2"/>
    );
}

export default NewsImage;