export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const DASHBOARD = '/dashboard';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const ADMIN_SURFERS = '/admin/surfers';
export const ADMIN_TRAININGS = '/admin/trainings';
export const ADMIN_BOTS = '/admin/bots';
export const ADMIN_PRODUCTS = '/admin/products';
export const ADMIN_PRODUCT = '/admin/product/:id';
export const ADMIN_SURFER = '/admin/surfer/:uid';
export const ADMIN_BOT = '/admin/bot/:uid';
export const ADMIN_TRAINING = '/admin/training/:id';
export const ADMIN_TRAINING_STATISTICS = '/admin/training/:id/statistics';
export const SUBSCRIBE = '/subscribe';
export const TEAM = '/team'
export const DASHBOARD_TRAINING = '/dashboard/training/:id';
export const DASHBOARD_TRAINING_STATISTICS = '/dashboard/training/:id/statistics';
export const PASSWORD_FORGET = '/pw-forget';

// Pages
export const PRIVACY_POLICY = '/privacy-policy';
export const COOKIE_POLICY = '/cookie-policy';
export const NON_DISCRIMINATION = '/non-discrimination';
export const TERMS = '/terms-and-conditions';