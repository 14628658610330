import React, {useEffect, useState} from 'react';
import {compose} from 'recompose';
import {withFirebase} from "../Firebase";
import {withAuthorization, withEmailVerification} from '../Session';
import {getProduct} from "@stripe/firestore-stripe-payments";
import {Container, Row, Col, Card, Badge, ListGroup} from "react-bootstrap";
import Table from "react-bootstrap/Table";

const Product = (props) => {
    const [id] = useState(props.router.params.id);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProductsAndPrices().then((value) => {
            setProduct(value);
            setLoading(false);
        }, (error) => {
            console.log("Error", error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProductsAndPrices = async () => {
        return await getProduct(props.firebase.payments, id, {
            includePrices: true,
            activeOnly: true,
        });
    }

    return (
        <>
            {loading && <div>Loading ...</div>}
            {product &&
                <Container className="content p-2 h-100">
                    <Row>
                        <Col xs={12} md={2}>
                            <Card className="pt-3">
                                <Card.Img variant="top" width="50%" src={product.images[0]}/>
                                <Card.Body>
                                    <Badge bg={product.active ? 'primary' : 'danger'}>
                                        {product.active ? 'Active' : 'Inactive'}
                                    </Badge>
                                    <Card.Title>{product.name}</Card.Title>
                                    <Card.Text>
                                        {product.description}
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>Product ID: {product.id}</ListGroup.Item>
                                    <ListGroup.Item>Role: {product.role}</ListGroup.Item>
                                    <ListGroup.Item>Tax code: {product.tax_code}</ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col xs={12} md={10}>
                            <Card className="pt-3">
                                <Card.Body>
                                    <Card.Title>Prices</Card.Title>
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th>Price ID</th>
                                            <th>Price</th>
                                            <th>Currency</th>
                                            <th>Type</th>
                                            <th>Recurring</th>
                                            <th>Interval count</th>
                                            <th>Trial period days</th>
                                            <th>Tax Behavior</th>
                                            <th>Interval count</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {product.prices.map((price, index) => (
                                            <tr key={price.id}>
                                                <td>{price.id}</td>
                                                <td>£{price.unit_amount / 100}</td>
                                                <td>{price.currency}</td>
                                                <td>{price.type}</td>
                                                <td>{price.recurring ? 'Yes' : 'No'}</td>
                                                <td>{price.interval_count}</td>
                                                <td>{price.trial_period_days}</td>
                                                <td>{price.tax_behavior}</td>
                                                <td>{price.interval_count}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );

}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase,
)(Product);