import React from 'react';
import {SignUpForm} from "./sign-up-form";
import {Row, Col, Card} from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import {HiOutlinePencil} from "react-icons/hi";
import GoogleSignInForm from "./google-sign-in-form";

const SignUp = () => (

    <Container className="content pt-5">
        <Row>
            <Col md={{span: 3, offset: 4}}>
                <Card className="pt-3">
                    <Card.Header><HiOutlinePencil/> Create account</Card.Header>
                    <Card.Body>
                        <div className="manual-section">
                            <SignUpForm/>
                        </div>
                        <div className="divider-wrapper">
                            <span className="divider">Or</span>
                        </div>
                        <div className="social-section">
                            <GoogleSignInForm/>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);

export default SignUp;
