import React from "react";
import {Card, Col, Row, Table} from "react-bootstrap";
import moment from "moment";
import StatisticsHeatScores from "./statistics-heat-scores";
import StatisticsSurfer from "./statistics-surfer";

const StatisticsHeats = (props) => {
    const {training, heats} = props;
    return (
        <Card className="pt-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                Heats
            </Card.Header>
            <Card.Body>
                {heats.length !== 0 && heats.map((heat, key) =>
                    <Card key={heat.key}>
                        <Card.Header
                            className="d-flex justify-content-between align-items-center stats-heat-card-header">
                            <span className="pl-1">Heat {heats.length - key}</span>
                        </Card.Header>
                        <Row className="pt-3">
                            <Col xs={12} md={6}>
                                <StatisticsHeatScores heat={heat} training={training}/>
                            </Col>
                            <Col xs={12} md={6}>
                                <StatisticsDates heat={heat}/>
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col xs={12} md={12}>
                                {training.surfers && Object.keys(training.surfers).map((uid, i) => (
                                    <StatisticsSurfer heat={heat}
                                                      training={training}
                                                      key={i}
                                                      surferSelected={{
                                                          data: training.surfers[uid],
                                                          uid: uid
                                                      }}/>
                                ))}
                            </Col>
                        </Row>
                    </Card>
                )}

            </Card.Body>
        </Card>
    );
}

export const StatisticsDates = (props) => {
    const {heat} = props;
    return (
        <Table responsive>
            <tbody>
            <tr>
                <th scope="row">Started</th>
                <td>{heat.heat.startTime && moment(heat.heat.startTime).format(`LLL`)}</td>
            </tr>
            <tr>
                <th scope="row">Finished</th>
                <td>{heat.heat.endTime && moment(heat.heat.endTime).format(`LLL`)}</td>
            </tr>
            </tbody>
        </Table>
    );
}

export default StatisticsHeats;