import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {withFirebase} from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import {Form, Button, Alert} from 'react-bootstrap'

const PasswordForgetFormBase = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: '',
        error: null,
    });

    useEffect(() => {
        props.firebase.onAuthUserListener(() => {
            navigate(ROUTES.DASHBOARD)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = event => {
        const {email} = state;

        props.firebase
            .doPasswordReset(email)
            .then(() => {
                setState({...state});
            })
            .catch(error => {
                setState({...state, error});
            });

        event.preventDefault();
    };

    const onChange = event => {
        setState({...state, [event.target.name]: event.target.value});
    };

    const {email, error} = state;

    const isInvalid = email === '';

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="form-element" controlId="email">
                <Form.Control
                    name="email"
                    value={email}
                    onChange={onChange}
                    type="text"
                    placeholder="Email Address"
                    aria-describedby="passwordHelpBlock"
                />
                <Form.Text id="passwordHelpBlock" muted>
                    Your will receive an email with a link to reset your password.
                </Form.Text>
            </Form.Group>
            <div className="d-grid gap-2 pb-3">
                <Button disabled={isInvalid} type="submit">
                    Reset My Password
                </Button>
            </div>
            <p>Already have an account? <Link to={ROUTES.SIGN_IN}>Sign In here</Link>.</p>
            {error && <Alert variant="danger">{error.message}</Alert>}
        </Form>
    );
}

const PasswordForgetLink = () => (
    <div>
        <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
    </div>
);

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export {PasswordForgetForm, PasswordForgetLink};