import React, {useEffect, useState} from 'react';
import {withFirebase} from "../Firebase";
import GetSurfer from "../Surfer/get-surfer";
import {Table} from "react-bootstrap";
import {BsFillPersonCheckFill} from "react-icons/bs";
import Loading from "../Views/loading";
import {onValue, orderByChild, equalTo, query} from "firebase/database";

const TrainingGetHeatScores = (props) => {
    const {heat, training, running, handleSeeCompetitor} = props;
    const [loading, setLoading] = useState(true);
    const [scores, setScores] = useState([]);
    const firebaseRef = props.firebase.scores();

    useEffect(() => {
        onValue(query(firebaseRef, orderByChild("trainingId"),equalTo(training.trainingId)), onFirebaseValueChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onListenForScores = async () => {
        if (heat.key) {
            if (training) {
                const data = ({training: training, heatId: heat.key});
                const getScores = props.firebase.cloud('getScores');
                await getScores(data).then(result => {
                    return result.data;
                }).then(scores => {
                    setLoading(false);
                    setScores(scores);
                });
            }
        }
    }

    const onFirebaseValueChanged = snapshot => {
        onListenForScores().then(() => {
            setLoading(false);
        });
    }

    const need = running ? 'Needs' : 'Needed';

    return (
        <>
            {loading && <Loading size="sm"/>}
            {scores.results &&
                <Table striped hover size="sm" responsive>
                    <thead>
                    <tr>
                        <th>Full name</th>
                        <th>1#</th>
                        <th>2#</th>
                        <th>Total</th>
                        <th>{need}</th>
                        {running && heat.heat.priority &&
                            <th style={{textAlign: 'center'}}>Priority</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {scores && scores.results.map((competitor) => (
                        <tr key={competitor.surfer} className="cursor-pointer"
                            onClick={e => handleSeeCompetitor(competitor.surfer, heat.key)}>
                            <td><GetSurfer uid={competitor.surfer}/></td>
                            <td>{competitor.bestFirstWave ? competitor.bestFirstWave : ''}</td>
                            <td>{competitor.bestSecondWave ? competitor.bestSecondWave : ''}</td>
                            <td>{competitor.total ? competitor.total : '-'}</td>
                            <td>{competitor.needs ? competitor.needs : '-'}</td>
                            {running && heat.heat.priority &&
                                <td style={{textAlign: 'center'}}>{heat.heat.priority === competitor.surfer ?
                                    <BsFillPersonCheckFill/> : ''}</td>}
                        </tr>
                    ))}
                    </tbody>
                </Table>}
        </>
    );
}

export default withFirebase(TrainingGetHeatScores);