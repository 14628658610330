import React, {useState, useEffect} from 'react';
import {withFirebase} from "../Firebase";
import GetSurfer from "../Surfer/get-surfer";

const TrainingGetWinner = (props) => {
    const {heat, training} = props;
    const [scores, setScores] = useState([]);

    const getScores = async () => {
        if (heat.key && training) {
            const data = ({training: training, heatId: heat.key});
            const getScores = props.firebase.cloud('getScores');
            await getScores(data).then(result => {
                return result.data;
            }).then(scores => {
                setScores(scores);
            });
        }
    }

    useEffect(() => {
        getScores().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heat]);

    return (
        <div className="winner">
            {scores.results && <span><GetSurfer uid={scores.results[0].surfer}/> wins!</span>}
        </div>
    );
}

export default withFirebase(TrainingGetWinner);