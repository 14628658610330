import React, {useState} from 'react';
import {Card, Image} from "react-bootstrap";
import * as ROLES from "../../constants/roles";
import {AuthUserContext} from "../Session";

export const SurferCard = (props) => {
    const {surfer, uid, footer, displayUid} = props;
    const [image] = useState('/default-profile.png');
    const jerseyDefined = surfer.jerseyColor ? surfer.jerseyColor.toLowerCase() : 'red';
    return (
        <>
            <Card key={uid}>
                <Card.Body>
                    <div className="sidebar-user">
                        <Image src={surfer.picture ? surfer.picture : image} roundedCircle
                               style={{border: '4px inset ' + jerseyDefined}}
                               className="img-fluid rounded-circle mb-2 profile-img"/>
                        <div className="fw-bold card-surfer-body align-self-center">
                            {surfer.jerseyNumber} | {surfer.name} {surfer.surname}
                        </div>
                        {displayUid &&
                            <AuthUserContext.Consumer>
                                {(authUser) => (authUser && authUser.role === ROLES.ADMIN ?
                                    <small className="text-muted">UID: {uid}</small> : '')}
                            </AuthUserContext.Consumer>
                        }
                    </div>
                </Card.Body>
                {footer &&
                    <Card.Footer>
                        <small className="text-muted">{surfer.bio}</small>
                    </Card.Footer>
                }
            </Card>
        </>
    );
}

export default SurferCard;