import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

/**
 * This is a custom hook that we need after updating to React Router 6.
 * https://stackoverflow.com/questions/66465750/withrouter-is-not-exported-from-react-router-dom
 * @param Component
 * @returns {function(*)}
 */
export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}