import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {compose} from 'recompose';
import {withFirebase} from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import {Button} from 'react-bootstrap';
import {GoogleAuthProvider, EmailAuthProvider} from 'firebase/auth';
import {getAnalytics, logEvent} from "firebase/analytics";

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null
};

const GoogleSignInFormBase = (props) => {
    const navigate = useNavigate();
    const analytics = getAnalytics();
    const [state, setState] = useState(INITIAL_STATE);
    const [notification] = useState({
        request: true,
        accept: true,
        add: true,
    });

    const signInWithGoogle = () => {
        props.firebase.doSignInWithGoogle()
            .then((result) => {
                if (result.user) {
                    // If user has not got an account, create it.
                    const user = result.user;
                    logEvent(analytics, 'sign_up', {
                        method: 'google'
                    });
                    props.firebase.cloud('saveNotification')({notification: notification});
                    return props.firebase.cloud('createSurfer')({
                        email: user.email,
                        name: user.displayName.split(' ')[0],
                        surname: user.displayName.split(' ')[1],
                        picture: user.photoURL,
                        uid: user.uid
                    });
                }
            })
            .catch((error) => {
                if (error.code === 'auth/account-exists-with-different-credential') {
                    const pendingCred = GoogleAuthProvider.credentialFromError(error);
                    const email = error.customData.email;
                    props.firebase.fetchSignInMethodsForEmail(email)
                        .then((methods) => {
                            if (methods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
                                const password = prompt(`Please enter your password for ${email}:`);
                                props.firebase.signInWithEmailAndPassword(email, password)
                                    .then((userCredential) => {
                                        return props.firebase.linkWithCredential(userCredential.user, pendingCred);
                                    })
                                    .then(() => {
                                        console.log("Google account linked to existing account");
                                        navigate(ROUTES.DASHBOARD);
                                    })
                                    .catch((linkError) => {
                                        setState({error: linkError});
                                    });
                            }
                        });
                }
            });
        navigate(ROUTES.DASHBOARD);
    };

    return (
        <div className="d-grid gap-2">
            {state.error && <p>{state.error.message}</p>}
            <Button variant="" className="social-btn" type="button" onClick={signInWithGoogle}>
                <span className="social-logo-wrapper">
                    <img alt="google-logo" src="/google-logo.svg"/>
                </span>
                <span className="social-text">Continue with Google</span>
            </Button>
        </div>
    );
};

const GoogleSignInForm = compose(withFirebase)(GoogleSignInFormBase);

export default GoogleSignInForm;
