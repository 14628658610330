import React from 'react';
import {withFirebase} from "../Firebase";
import 'react-input-range/lib/css/index.css';
import moment from 'moment';
import GetSurfer from "../Surfer/get-surfer";
import {Table, Card} from "react-bootstrap";

const StatisticsDetails = (props) => {
    const {loading, training} = props;

    return (
        <div>
            {loading && <div>Loading...</div>}
            <Card>
                <Card.Header className="d-flex mt-3 justify-content-between align-items-center">
                    Details
                </Card.Header>
                <Card.Body>
                    {training && training.author &&
                        <Table striped hover size="sm" responsive>
                            <thead>
                            <tr>
                                <th>Title</th>
                                <th>Created</th>
                                <th>Time</th>
                                <th>Created by</th>
                                <th>Judged by</th>
                                <th>Competitors</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr key={training.key}>
                                <td>{training.title}</td>
                                <td>{moment(training.created).format('LLL')}</td>
                                <td>{training.trainingTime} minutes</td>
                                <td>
                                    <GetSurfer uid={training.author}/>
                                </td>
                                <td>
                                    {training.judges && Object.keys(training.judges).map((uid, i) => (
                                        <li key={uid} className="no-style">
                                            <GetSurfer uid={uid}/>
                                        </li>
                                    ))}
                                </td>
                                <td>
                                    {training.surfers && Object.keys(training.surfers).map((uid, i) => (
                                        <li key={uid} className="no-style">
                                            <GetSurfer uid={uid}/>
                                        </li>
                                    ))}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    }
                </Card.Body>
            </Card>
        </div>
    );
}

export default withFirebase(StatisticsDetails);