import React, {useState, useEffect} from 'react';
import {withFirebase} from "../Firebase";
import * as ROLES from "../../constants/roles";
import AuthUserContext from "../Session/context";
import {Link} from "react-router-dom";
import {onValue} from "firebase/database";

const TeamGetStatus = (props) => {
    const {uid, currentUser} = props;
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        getMemberStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid, currentUser]);

    const getMemberStatus = () => {
        onValue(props.firebase.surferTeamMember(currentUser, uid), snapshot => {
            const statusObject = snapshot.val();
            if (statusObject) {
                setStatus(statusObject);
            }
            setLoading(false);
        });
    }

    return (
        <div>
            {loading && <div>Loading...</div>}
            {status &&
                <AuthUserContext.Consumer>
                    {authUser => (authUser && (authUser.role === ROLES.ADMIN) ?
                        <WithPermissions status={status} uid={uid}/> :
                        <WithoutPermissions status={status} uid={uid}/>)}
                </AuthUserContext.Consumer>
            }
        </div>
    );
}

const WithPermissions = (props) => (
    <Link to={'/admin/surfer/' + props.uid}>
        <span key={props.uid}>{props.status.toUpperCase()}</span>
    </Link>
);

const WithoutPermissions = (props) => (
    <span key={props.uid}>{props.status.toUpperCase()}</span>
);

export default withFirebase(TeamGetStatus);